import React from 'react';
import { connect } from 'react-redux';
import draftToHtml from 'draftjs-to-html';

import { IAppState } from 'store';
import { IUserDto } from '../../../../../server/src/dto/IUser';
import { MembershipUser } from '../../../../../server/src/entity/memberships/membershipUser';


interface IContent {
  key: string;
  data: any;
  text: string;
  type: string;
}

const openTokens = ['[loggedin]', '[subscribed'];
const closeTokens = ['[/loggedin]', '[/subscribed]'];

const tokens = [
  {
    open: (str: string): boolean => str === '[loggedin]',
    close: (str: string): boolean => str === '[/loggedin]',
    testFn: ({ user, ...rest }): boolean => Boolean(user && user.id),
    priorState: false,
  },
  {
    open: (str: string): boolean => str.match(/\[subscribed\(.+\)\]/),
    close: (str: string): boolean => str === '[/subscribed]',
    testFn: ({ user, myMemberships, key, ...rest }): boolean => {
      console.log('myMemberships', myMemberships)
      const parenContents = key.match(/\(.+\)/);
      let str = parenContents[0];
      str = str.replace(/\'/gi, '"');
      const strArr: string[] = [];
      for (let ix = 0; ix < str.length; ix++) {
        if (ix != 0 && ix < str.length - 1) {
          strArr.push(str[ix]);
        }
      }

      const membershipList = `[ ${strArr.join('')} ]`;
      console.log('membershipList', membershipList);
      const array = JSON.parse(membershipList);
      const membershipSet = new Set(myMemberships.map(i => i.membershipName));
      const found = array.find((i: string) => membershipSet.has(i));
      return Boolean(user && user.id && found);
    },
    priorState: false,
  },
];


export const filterContent = (content: IContent[], user, myMemberships) => {
  let ix = 0;
  const tokenItems = {};
  const results = [];
  let shouldAddContent = true;
  console.log('content', content);
  for (const c of content) {
    const foundOpening = tokens.find((t) => t.open(c.text));
    const foundClosing = tokens.find((t) => t.close(c.text));

    if (foundOpening) {
      foundOpening.priorState = shouldAddContent;
      shouldAddContent = foundOpening.testFn({ user, myMemberships, key: c.text });
      continue;
    }

    if (foundClosing) {
      console.log('foundClosing', foundClosing, c.text)
      shouldAddContent = foundClosing.priorState;
      continue;
    }
    if (shouldAddContent) {
      results.push(c);
      console.log('adding content', c.text);
    }
  }
  return results;
};


const DraftToHtml = ({
  content,
  user,
  myMemberships,
}: {
  content: any;
  user: IUserDto;
  myMemberships: MembershipUser[];
}) => {
  if (!content) {
    return [];
  }
  const blocks = filterContent(content.blocks, user, myMemberships);
  const newContent = {
    ...content,
    blocks,
  };
  return <div dangerouslySetInnerHTML={{ __html: draftToHtml(newContent) }} />;
};

const mapState = ({ app }: IAppState, ownProps) => {
  const { user, myMemberships } = app;

  return {
    user,
    myMemberships,
  };
};

export default connect(mapState)(DraftToHtml);
