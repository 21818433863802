import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { IAppState } from 'store';
import { Store } from '../../../../../server/src/entity/store/store';
import { isClient } from 'utils';
import { User } from '../../../../../server/src/entity/user';

interface Props {
  store: Store;
  user: User;
}

const Analytics: React.FC<Props> = ({ store, user }) => {
  const [gtm, setGtm] = useState(false);
  useEffect(() => {
    if (isClient() && store && !gtm) {
      if (store.gaId) {
        ReactGA.initialize(store.gaId.trim());
        ReactGA.set({ userId: user.id });
      }
      if (store.gtmId) {
        eval(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${store.gtmId.trim()}')`);
      }
      setGtm(true);
    }
  }, [store]);

  return (
    <React.Fragment></React.Fragment>
  );
};

const state = ({ app }: IAppState) => {
  const { store, user } = app;
  return {
    user,
    store,
  };
};

export default connect(state)(Analytics);
