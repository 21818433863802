import React, { useState } from 'react';
import css from './AdminLinks.module.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from 'store';
import { StorePage } from '../../../../../server/src/entity/store/storePage';
import { EPageType } from '../../../../../server/src/entity/store/enums';

const AdminLinks = ({ storePages = [] }) => {
  return (
    <React.Fragment>
      <div>
        <Link to={'/admin/categories'}>Categories</Link>
      </div>
      <div>
        <Link to={'/admin/products'}>Products</Link>
      </div>
      <div>
        <Link to={'/admin/orders'}>Orders</Link>
      </div>
      <div>
        <Link to={'/admin/assets'}>Assets</Link>
      </div>
      <div>
        <Link to={'/admin/promotions'}>Promotions</Link>
      </div>
      <div>
        <Link to={'/admin/mailinglist'}>Mailing List</Link>
      </div>
      <div>
        <Link to={'/admin/memberships'}>Memberships</Link>
      </div>
      <hr />
      <div>
        <Link to={`/admin/editPage`}>
          <b>Pages</b>
        </Link>
        <div>
          <Link to={`/admin/editPage?page=home`}>Home</Link>
        </div>
        {storePages.map((page: StorePage) => {
          return (
            <Link to={`/admin/editPage?page=${page.slug}`}>
              <div key={page.id}>{page.name}</div>
            </Link>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState) => {
  let { storePages = [] } = app;
  storePages = storePages.filter((i) => i.pageType === EPageType.CUSTOM_PAGE);
  return {
    storePages,
  };
};

export default connect(mapState, null)(AdminLinks);
