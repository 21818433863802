import { IApp, IAdmin } from '../../store';
import { Locale } from './types';

export const ActionTypes = {
  SETLOCALE: 'app/set-locale',
  SET_APP: 'set_app',
  SET_ADMIN: 'set_admin',
};

export const setLocale = (locale: Locale) => ({
  type: ActionTypes.SETLOCALE,
  payload: locale,
});

export const setApp = (app: Partial<IApp>) => ({
  type: ActionTypes.SET_APP,
  payload: app,
});

export const setAdmin = (admin: Partial<IAdmin>) => ({
  type: ActionTypes.SET_ADMIN,
  payload: admin,
});
