import React from 'react';
import { get, startCase, groupBy, keyBy } from 'lodash';
import { Row, Col } from 'reactstrap';
import MyInput from 'components/MyInput';
import { NONE_CONST, strStartCase } from 'utils';
import { IAppState } from 'store';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';

const ProductOptions = ({ product, productOptions, setProductOptions }) => {
  const imgLookup = keyBy(product.images, (i) => i.shortId);
  const groupedAttributes = groupBy(product?.attributes, (p) => p.key);
  const attrIdLookup = keyBy(product?.attributes, 'id');
  return (
    <React.Fragment>
      {product?.attributes?.length > 0 && <h5>Options</h5>}
      {Object.keys(groupedAttributes)?.map((attribute) => {
        return (
          <Row key={attribute} className="spacer">
            <Col>
              {strStartCase(attribute)}
              {product?.requiredAttributes?.includes(attribute) ? '*' : ''}
            </Col>
            <Col>
              <MyInput
                type="select"
                path={`attributes.${attribute}`}
                state={productOptions}
                setState={setProductOptions}
              >
                <option value={NONE_CONST}>None</option>
                {groupedAttributes[attribute]?.map((attr) => {
                  let valueStr = '';
                  if (attr.price > 0) {
                    valueStr = `+$${attr.price}`;
                  } else if (attr.price < 0) {
                    valueStr = `-$${attr.price * -1}`;
                  }

                  if (attr.qty === '0') {
                    valueStr += ' (Sold Out)';
                  }
                  const optValue = JSON.stringify(attr);
                  return (
                    <option value={optValue}>
                      {strStartCase(attr.value)} {valueStr}
                    </option>
                  );
                })}
              </MyInput>
            </Col>
            <Col xs={3}>
              {(() => {
                try {
                  const imgShortId = JSON.parse(
                    get(productOptions, `attributes.${attribute}`, '{}'),
                  ).imageShortId;
                  if (imgShortId) {
                    const img = imgLookup[imgShortId];
                    if (img) {
                      return (
                        <div style={{ width: '100%', height: 'auto' }}>
                          <ProductThumbnail allowEnlarge shortId={img.shortId} ext={img.ext} />
                        </div>
                      );
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
                return null;
              })()}
            </Col>
          </Row>
        );
      })}
      {product?.attributes && product.attributes.length > 0 && (
        <div>
          <small>* features are required</small>
          <hr />
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductOptions;
