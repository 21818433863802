import React, { useState } from 'react';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import GoogleLoginImg from '../../assets/btn_google_signin_dark_normal_web.png';
import { IAppState } from 'store';
import { getApiUrl } from 'api';
import css from './Login.module.css';
import MyInput from 'components/MyInput';
import { config } from '../../config';
import * as api from '../../api';
import { Link } from 'react-router-dom';
import { setApp } from 'store/app/actions';

const Login = ({ config }) => {
  const dispatch = useDispatch();
  const [showRegister, setShowRegister] = useState(false);
  const [loginForm, setLoginForm] = useState({});
  const [error, setError] = useState(null);

  const register = async () => {
    const { email, password: password1, password2 } = loginForm;
    if (email.length < 3) {
      alert('Email is not valid');
      return;
    }
    const emailMatch = email.match(/@/g);

    if (emailMatch.length !== 1) {
      alert('Email is not valid');
      return;
    }

    if (password1 !== password2) {
      alert('Passwords do not match');
      return;
    }

    if (password1.length < 5) {
      alert('Passwords must be 6 characters long or more');
      return;
    }

    const uppercase = password1.match(/[A-Z]/g);
    const lowercase = password1.match(/[a-z]/g);
    const number = password1.match(/[0-9]/g);

    if (!uppercase || uppercase.length < 1) {
      alert('Passwords must have at least 1 UPPER case character');
      return;
    }

    if (!lowercase || lowercase.length < 1) {
      alert('Passwords have at least 1 lower case character');
      return;
    }

    if (!number || number.length < 1) {
      alert('Passwords have at least 1 numeric value 0-9');
      return;
    }

    const resp = await api.registerApi({
      email,
      password: password1,
    });

    if (resp.success) {
      const data = await api.bootstrapApi();
      dispatch(setApp(data));
      window.location.href = '/';
    } else {
      alert('Error');
    }
  };
  const login = async () => {
    const { email, password } = loginForm;
    setError(null);

    const resp = await api.loginApi({
      email,
      password,
    });
    if (resp.success) {
      const data = await api.bootstrapApi();
      dispatch(setApp(data));
      window.location.href = '/';
    } else {
      setError('Bad login. Please try again.');
    }
  };
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h1>{showRegister ? 'Register' : 'Login'}</h1>
        <Row>
          <Col>
            {/* <div className={css.login}>
              One click sign in with Google:
              <br />
              <img
                className={css.img}
                onClick={() => {
                  const loc = `http://api.${window.location.host}/auth/google?host=${window.location.host}`;
                  window.location = loc;
                }}
                src={GoogleLoginImg}
              />
            </div> */}
            <div className="spacer" />
            <div className={`${css.login}`}>
              <div style={{ clear: 'both', margin: '3px' }}>
                <div style={{ textAlign: 'right' }}>
                  <Link
                    onClick={() => {
                      setShowRegister(!showRegister);
                    }}
                  >
                    {!showRegister ? 'Sign Up' : 'Login'}
                  </Link>
                </div>
              </div>
              Email: <br />
              <MyInput type="text" state={loginForm} setState={setLoginForm} path="email" />
              Password: <br />
              <MyInput
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !showRegister) {
                    login();
                  }
                }}
                type="password"
                state={loginForm}
                setState={setLoginForm}
                path="password"
              />
              {showRegister && (
                <div>
                  Confirm Password: <br />
                  <MyInput
                    type="password"
                    state={loginForm}
                    setState={setLoginForm}
                    path="password2"
                  />
                </div>
              )}
              <br />
              <div className={css.forgot}>
                <Link to={'/resetpassword'}>Forgot Password</Link>
              </div>
              {error && <div className="danger">{error}</div>}
              {showRegister && <Button onClick={register}>Register</Button>}
              {!showRegister && <Button onClick={login}>Sign in</Button>}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState) => {
  return {
    config: app.config,
  };
};

export default connect(mapState, null)(Login);
