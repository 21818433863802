import React, { useEffect, useCallback, useRef } from 'react';
import css from './Modal.module.css';

const Modal = ({ onOutsideClick, children }) => {
  const ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (onOutsideClick) {
          onOutsideClick();
        }
      }
    };
    const clickOutside = document.addEventListener('mousedown', handler);
    return clickOutside;
  }, []);
  return (
    <div className={css.modal}>
      <div ref={ref} className={css.modalContent}>
        <div className={css.close} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
