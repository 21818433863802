import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import css from './CartHover.module.css';
import { strStartCase } from 'utils';
import { Cart } from '../../../../../server/src/entity/store/cart';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';

const CartHover = ({ children, HoverComponent, cartItems }) => {
  return (
    <div className={css.cartHoverWrapper}>
      {cartItems.map((item: Cart) => {
        return (
          <div
            className={css.itemWrapper}
            style={{
              height: 100 + (10 * Object.keys(item.attributes).length) + 'px',
            }}
          >
            <div className={css.img}>
              {item.product.images &&
                (() => {
                  const imgAttr = (Object.values(item.attributes) || []).find(
                    (a) => !!a.imageShortId,
                  );
                  let img = item.product.images[0];
                  if (imgAttr) {
                    const maybeImg = item.product.images.find(
                      (a) => a.shortId === imgAttr.imageShortId,
                    );
                    if (maybeImg) {
                      img = maybeImg;
                    }
                  }
                  return <ProductThumbnail ext={img?.ext} shortId={img?.shortId} />;
                })()}
            </div>
            <div className={css.details}>
              <div className={css.name}>{item.product.name}</div>
              <div className={css.qty}>Qty: {item.quantity}</div>
              <div className={css.attributes}>
                {Object.keys(item.attributes).map((attrKey) => {
                  const attrValue = item.attributes[attrKey].value;
                  return (
                    <div>
                      {strStartCase(attrKey)}: {strStartCase(attrValue)}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={css.price}>${item.price * item.quantity}</div>
          </div>
        );
      })}
    </div>
  );
};

const mapState = (state: IAppState) => {
  const { storePages = [], cartItems, user, store, storeAdmin, categories = [] } = state.app;
  return {
    cartItems,
  };
};

export default connect(mapState, null)(CartHover);
