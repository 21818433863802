import moment from 'moment';
import { store } from 'react-notifications-component';
import { customAlphabet } from 'nanoid';
import { Cart } from '../../../server/src/entity/store/cart';

export const NONE_CONST = '__NONE__';
const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';
const customId = customAlphabet(ALPHABET, 10);

export const getNewId = (): string => {
  return customId();
};

export const uriEncoded = (s: string): string => {
  s = s.trim().replace(/\s+?/gi, '_');
  return encodeURI(s);
};

export const isClient = () => {
  if (typeof window !== 'undefined') {
    return true;
  }
  return false;
};

export const notify = ({ error = false, message = '' }) => {
  store.addNotification({
    title: error ? 'Whoops' : 'Success!',
    message,
    type: error ? 'danger' : 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
};

export const sleep = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const getItemPrice = (item: Cart) => {
  const attrPrice = Object.values(item.attributes || {}).reduce((acc, val) => {
    if (val.price) {
      acc += Number(val.price);
    }
    return acc;
  }, 0.0);
  const price = (item.price + attrPrice) * item.quantity;
  return Number(price.toFixed(2));
};

export const strStartCase = (str: string): string => {
  const arr = new Array(str.length);
  for (let i = 0; i < str.length; i++) {
    if (i === 0 && str[i].match(/[A-Za-z]/)) {
      arr.push(str[i].toUpperCase());
    } else {
      arr.push(str[i]);
    }
  }
  return arr.join('');
};

export const renderDate = (str: string): string => {
  if (!str) {
    return '';
  }
  try {
    const m = moment(str);
    if (!m || !m.format) {
      return '';
    }
    return m.format('YYYY-MM-DD h:mm:ssa');
  } catch (err) {
    return 'Invalid Date';
  }
};
