import { get } from 'lodash';
import superagent from 'superagent';
import { store } from 'react-notifications-component';
import { IApp } from 'store';
import { IProductDto } from '../../../server/src/dto/IProduct';
import { StripeCreditCard } from '../../../server/src/entity/stripeCreditCard';
import * as routeTypes from '../../../server/src/types/route-types';
import { config } from 'config';
import { Image } from '../../../server/src/entity/store/image';
import { adminSendMailerRoute } from '../../../server/src/types/route-types';

const CLIENT = typeof window !== 'undefined';

const host = CLIENT && window.location.hostname;

export const getApiUrl = () => {
  let url;
  if (host.includes('dev.')) {
    url = `//api.${host}:5000`;
  } else {
    url = `//${host}/api`;
  }
  return url;
};

const getUrl = (path: string) => {
  if (path.startsWith('http')) {
    return path;
  }
  let url;
  if (host.includes('dev.')) {
    url = `//api.${host}:5000${path}`;
  } else {
    url = `//${host}/api${path}`;
  }
  return url;
};

const req = async ({
  url,
  body = {},
  method,
  query = {},
}: {
  method: routeTypes.TMethod;
  url: string;
  body?: any;
  query?: object;
}) => {
  let resp;
  try {
    resp = await superagent[method](getUrl(url))
      .withCredentials()
      .query(query)
      .send(body);
    return resp.body;
  } catch (err) {
    const msg = get(err, 'response.body.error');
    store.addNotification({
      title: 'API Error',
      message: msg || err.status + ' ' + err.toString(),
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
    throw err;
  }
};

export const logoutApi = async () => {
  await req({
    method: 'get',
    url: '/logout',
  });
};

export const updateMyAccountApi = async (props: any) => {
  const resp = await req({
    method: 'put',
    url: '/user',
    body: props,
  });
};

export const setAddressApi = async (props: any) => {
  await req({
    method: 'put',
    url: '/user/address',
    body: props,
  });
};

export const deleteAddressApi = async (id: any) => {
  await req({
    method: 'delete',
    url: `/user/address/${id}`,
  });
};

export const bootstrapApi = async (): Promise<IApp> => {
  const body = await req({
    method: 'post',
    url: '/bootstrap',
    body: {
      host: window.location.hostname,
    },
  });
  return (body as any) as IApp;
};

export const loadProductsForCategoryApi = async ({
  shortId,
  length,
}: {
  shortId: string;
  length?: number;
}): Promise<any[]> => {
  const body = await req({
    method: 'get',
    url: `/category/${shortId}/products`,
    query: {
      length,
    },
  });
  return body;
};

export const saveCategoryAdminApi = async (props) => {
  const body = await req({
    method: 'post',
    url: `/admin/category/${props.id}`,
    body: props,
  });
  return body;
};

export const getProductsAdminApi = async (): Promise<IProductDto[]> => {
  const body = await req({
    url: '/admin/products',
    method: 'get',
  });
  return body.results;
};

export const getStoreAdminApi = async (): Promise<any> => {
  const body = await req({
    url: '/admin/store',
    method: 'get',
  });
  return body;
};

export const saveStoreAdminApi = async (props): Promise<any> => {
  const body = await req({
    url: '/admin/store',
    method: 'post',
    body: props,
  });
  store.addNotification({
    title: 'Successs',
    message: 'Store settings saved',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 1000,
      onScreen: true,
    },
  });
  return body;
};

export const saveProductAdminApi = async (props): Promise<any> => {
  if (props.product.id) {
    // update
    const body = await req({
      url: `/admin/product/${props.product.id}`,
      method: 'put',
      body: props,
    });

    return body;
  }

  // create
  const body = await req({
    url: '/admin/product',
    method: 'post',
    body: props,
  });

  return body;
};

export const uploadImagesApi = async ({ formData }): Promise<void> => {
  const body = await req({
    method: 'post',
    url: '/admin/image/upload',
    body: formData,
  });
  return body;
};

export const deleteImagesApi = async ({ imageIds }): Promise<void> => {
  await req({
    method: 'delete',
    url: '/admin/image',
    body: {
      imageIds,
    },
  });
};

export const uploadFilesApi = async ({ formData }): Promise<void> => {
  const body = await req({
    method: 'post',
    url: '/admin/file',
    body: formData,
  });
  return body;
};

export const deleteFileApi = async (id) => {
  const resp = await req({
    method: 'delete',
    url: `/admin/file/${id}`,
  });
  return resp;
};

export const loadSingleProductApi = async ({ shortId }): Promise<any> => {
  const body = await req({
    method: 'get',
    url: `/product/${shortId}`,
  });
  return body;
};

export const softDeleteCategoryAdminApi = async ({ id }): Promise<any> => {
  await req({
    method: 'delete',
    url: `/admin/category/${id}`,
  });
};

export const addToCartApi = async ({ productId, quantity, attributes }): Promise<any> => {
  await req({
    method: 'post',
    url: '/cart',
    body: {
      productId,
      quantity,
      attributes,
    },
  });
};

export const updateCartApi = async ({ itemId, quantity }): Promise<any> => {
  await req({
    method: 'put',
    url: `/cart/${itemId}`,
    body: {
      quantity,
    },
  });
};

export const removeFromCartApi = async ({ itemId }): Promise<any> => {
  await req({
    method: 'delete',
    url: `/cart/${itemId}`,
  });
};

export const fetchCartApi = async (): Promise<any> => {
  const body = await req({
    method: 'get',
    url: '/cart',
  });
  return body;
};

export const addCardApi = async (data): Promise<any> => {
  const body = await req({
    method: 'post',
    url: '/user/card',
    body: data,
  });
  return body;
};

export const getCardsApi = async (): Promise<{ cards: StripeCreditCard[] }> => {
  const body = await req({
    method: 'get',
    url: '/user/cards',
  });
  return body;
};

export const submitOrderViaPaypal = async ({
  payload,
  userTotal,
  selectedAddress,
  newAddress,
  cartItemIds,
  comment,
  discount,
}): Promise<any> => {
  const resp = await req({
    method: 'post',
    url: '/cart/submitViaPaypal',
    body: {
      payload,
      userTotal,
      selectedAddress,
      newAddress,
      cartItemIds,
      comment,
      discount,
    },
  });
};

export const submitOrderViaCardApi = async ({
  cardId,
  userTotal,
  selectedAddress,
  newAddress,
  cartItemIds,
  stripeToken,
  comment,
  discount,
}): Promise<any> => {
  const body = await req({
    method: 'post',
    url: '/cart/submitViaCard',
    body: {
      cardId,
      userTotal,
      selectedAddress,
      newAddress,
      cartItemIds,
      stripeToken,
      comment,
      discount,
    },
  });
  return body;
};

export const getMyOrdersApi = async () => {
  const body = await req({
    method: 'get',
    url: '/orders',
  });
  return body;
};

export const getOrdersAdminApi = async () => {
  const body = await req({
    method: 'get',
    url: '/admin/orders',
  });
  return body;
};

export const refundOrderAdminApi = async ({ shortId }) => {
  const body = await req({
    method: 'post',
    url: `/admin/order/${shortId}/refund`,
  });
  return body;
};

export const captureOrderAdminApi = async ({ shortId }) => {
  const body = await req({
    method: 'post',
    url: `/admin/order/${shortId}/capture`,
  });
  return body;
};

export const shipOrderAdminApi = async ({ shortId, shipperName, trackingId }) => {
  const body = await req({
    method: 'post',
    url: `/admin/order/${shortId}/ship`,
    body: {
      shipperName,
      trackingId,
    },
  });
  return body;
};

export const mailingListSignUpApi = async ({ email }) => {
  try {
    await req({
      method: 'post',
      url: `/mailinglist/signup`,
      body: {
        email,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const adminSaveHomePageContentApi = async (data) => {
  await req({
    method: 'put',
    url: '/admin/content/home',
    body: data,
  });
};

export const setContentPayloadApi = async ({
  payload,
  pageId,
  contentType,
  contentName,
  pageSettings,
}) => {
  await req({
    method: 'put',
    url: '/admin/content',
    body: {
      payload,
      pageId,
      contentType,
      contentName,
      pageSettings,
    },
  });
};

export const copyProductAdminApi = async ({ productId }) => {
  await req({
    method: 'post',
    url: `/admin/product/${productId}/copy`,
  });
};

export const updatePasswordApi = async ({ password }) => {
  await req({
    method: 'post',
    url: '/user/update/password',
    body: {
      password,
    },
  });
};

export const loginApi = async ({ email, password }) => {
  const body = await req({
    method: 'post',
    url: '/user/login',
    body: {
      email,
      password,
    },
  });
  return body;
};

export const registerApi = async ({ email, password }) => {
  const body = await req({
    method: 'post',
    url: '/user/register',
    body: {
      email,
      password,
    },
  });
  return body;
};

export const helloApi = async () => {
  const body = await req({
    method: 'get',
    url: '/',
  });
  return body;
};

export const adminCreatePageApi = async ({ title, slug }) => {
  const body = await req({
    method: 'post',
    url: '/admin/page',
    body: {
      title,
      slug,
    },
  });
  return body;
};

export const resetPasswordApi = async ({ email }) => {
  const body = await req({
    method: 'post',
    url: '/resetpassword',
    body: {
      email,
    },
  });
  return body;
};

export const searchProductsApi = async ({ term }) => {
  const body = await req({
    method: 'get',
    url: '/search',
    query: {
      term,
    },
  });
  return body;
};

export const adminGetAssetsApi = async () => {
  const body = await req({
    method: routeTypes.adminGetAssetsRoute.method,
    url: routeTypes.adminGetAssetsRoute.uri,
  });
  return body;
};

export const adminDeleteAssetsApi = async ({ ids }) => {
  const body = await req({
    method: routeTypes.adminDeleteAssetsRoute.method,
    url: routeTypes.adminDeleteAssetsRoute.uri,
    body: {
      ids,
    },
  });
  return body;
};

export const discountLookupApi = async ({ code }) => {
  const body = await req({
    url: '/discountLookup',
    method: 'post',
    body: {
      code,
    },
  });
  return body;
};

export const adminGetDiscountsApi = async () => {
  const body = await req({
    url: '/admin/discount',
    method: 'get',
  });
  return body;
};

export const adminCreateDiscountCodeApi = async ({
  code,
  discountType,
  discountAmount,
  validUntil,
}) => {
  const body = await req({
    url: '/admin/discount',
    method: 'post',
    body: {
      code,
      discountType,
      discountAmount,
      validUntil,
    },
  });
  return body;
};

export const adminDeleteDiscountCodeApi = async ({ ids }) => {
  await req({
    url: '/admin/discount/delete',
    method: 'post',
    body: {
      ids,
    },
  });
};

export const subscribeToMembershipApi = async ({ stripeToken, membershipShortId }) => {
  const results = await req({
    url: `/membership/subscribe/${membershipShortId}`,
    method: 'post',
    body: {
      stripeToken,
      membershipShortId,
    },
  });
  return results;
};

export const adminUpdateMembership = async (state) => {
  const results = await req({
    url: `/admin/memberships/${state.id}`,
    method: 'put',
    body: state,
  });
  return results;
};

export const adminCreateMembership = async (state) => {
  const results = await req({
    url: '/admin/memberships',
    method: 'post',
    body: state,
  });
  return results;
};

export const adminGetMemberships = async () => {
  const results = await req({
    url: '/admin/memberships',
    method: 'get',
  });
  return results;
};

export const adminGetUserMemberships = async () => {
  const results = await req({
    url: '/admin/user_memberships',
    method: 'get',
  });
  return results;
};

export const adminGetMailingList = async () => {
  const results = await req({
    url: '/admin/list/customers',
    method: 'get',
  });
  return results;
};

export const adminAddEmailsToMailingList = async ({ emails }) => {
  const results = await req({
    url: '/admin/list/customers',
    method: 'post',
    body: {
      emails,
    },
  });
  return results;
};

export const reviewProduct = async ({ shortId, comment, rating }) => {
  const results = await req({
    url: `/product/${shortId}/review`,
    method: 'post',
    body: {
      shortId,
      comment,
      rating,
    },
  });
  return results;
};

export const fetchImage = async ({
  shortId,
  ext,
  full = false,
}: {
  shortId: string;
  ext: string;
  full?: boolean;
}) => {
  const url = `${config.cdnPrefix}/images/${shortId}/${full ? 'original' : 'thumb'}.${ext}`;
  console.log('url', url);
  const str = await req({
    url,
    method: 'get',
  });
  return str;
};

export const adminCreateMailerApi = async ({ body }) => {
  const results = await req({
    url: adminSendMailerRoute.uri,
    method: adminSendMailerRoute.method,
    body,
  });
  return results;
};
