import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Archive, Bag, Square, XSquare, Person } from 'react-bootstrap-icons';
import { groupBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';

import { Container, Row, Col, Input } from 'reactstrap';
import { uriEncoded } from 'utils';
import { ICategoryDto } from '../../../../../server/src/dto/ICategory';
import { IAppState } from '../../store';
import { Cart } from '../../../../../server/src/entity/store/cart';
import { IUserDto } from '../../../../../server/src/dto/IUser';
import * as api from '../../api';

import css from './StoreHeader.module.css';
import { StorePage } from '../../../../../server/src/entity/store/storePage';
import Searchbar from './Searchbar';
import MyHover from 'components/MyHover/MyHover';
import CartHover from './CartHover';

interface IProps {
  title: string;
  topCategories: ICategoryDto[];
  childCategories: { [id: string]: ICategoryDto[] };
  isStoreAdmin: boolean;
  isSiteAdmin: boolean;
  cartItems: Cart[];
  user: IUserDto;

  mainNavPages: StorePage[];
  pagesInCategoryLookup: { [catId: number]: StorePage[] };
  showMembershipTopBar: boolean;
}
class StoreHeader extends React.Component<IProps> {
  constructor(props) {
    super(props);
    this.state = {
      openChildCategory: null, //3,
      openTopCategory: null, // 1,
      hoverMenu: null,
    };
  }
  timer = null;

  render() {
    const {
      cartItems,
      logoUrl,
      isSiteAdmin,
      title,
      topCategories,
      childCategories,
      user,
      isStoreAdmin,
      mainNavPages,
      pagesInCategoryLookup,
      showMembershipTopBar,
    } = this.props;
    const { openTopCategory } = this.state;
    return (
      <React.Fragment>
        <div className={css.headerWrapper}>
          <Helmet
            defaultTitle={title}
            titleTemplate={`%s – ${title}`}
            link={[{ rel: 'icon', type: 'image/png', href: '' }]}
          />
          <Container fluid className="container-fluid">
            <Row>
              <Col xs={8} sm={4} md={4}>
                <Link style={{ textDecoration: 'none' }} to={'/'}>
                  {logoUrl && <img style={{ maxWidth: '100%', maxHeight: '60px' }} src={logoUrl} />}
                  {!logoUrl && (
                    <h1>
                      <Archive className={css.reactLogo} />{' '}
                      <span className={css.title}>{title}</span>
                    </h1>
                  )}
                </Link>
              </Col>
              <Col className="xs-hidden" xs={4} sm={4} md={5}>
                <div>
                  <Searchbar />
                </div>
              </Col>
              <Col>
                <div className={css.menuWrapper}>
                  <MyHover HoverComponent={<CartHover />} disabled={cartItems?.length === 0}>
                    <div className={`${css.menu} ${css.gray}`}>
                      <Link to={'/cart'}>
                        <FontAwesomeIcon
                          style={{ width: '20px' }}
                          className={css.faIcon}
                          icon={faShoppingCart}
                        />
                        <div className="sm-hidden">
                          &nbsp;Cart {cartItems?.length ? `(${cartItems?.length})` : ''}
                        </div>
                      </Link>
                    </div>
                  </MyHover>
                  {!user && (
                    <div className={css.menu}>
                      <Link to="/login">
                        <FontAwesomeIcon
                          style={{ width: '20px' }}
                          className={css.faIcon}
                          icon={faUser}
                        />
                        <div className="sm-hidden">&nbsp;Sign in</div>
                      </Link>
                    </div>
                  )}
                  {user && (
                    <MyHover
                      HoverComponent={
                        <div style={{ height: '120px' }}>
                          <div className={css.accountItem}>
                            <Link to={'/account'}>Profile</Link>
                          </div>
                          <div className={css.accountItem}>
                            <Link to={'/orders'}>Orders</Link>
                          </div>
                          {isStoreAdmin && (
                            <div className={css.accountItem}>
                              <Link to={'/admin'}>Admin</Link>
                            </div>
                          )}
                          <div className={css.accountItem}>
                            <Link
                              onClick={async () => {
                                await api.logoutApi();
                                window.location.href = '/';
                                window.location.reload();
                              }}
                            >
                              Logout
                            </Link>
                          </div>
                        </div>
                      }
                    >
                      <div className={css.menu}>
                        <Link to="/account">
                          <FontAwesomeIcon
                            className={css.faIcon}
                            style={{ width: '20px' }}
                            icon={faUser}
                          />
                          <div className="sm-hidden">&nbsp;Account</div>
                        </Link>
                      </div>
                    </MyHover>
                  )}
                </div>

                {/*               
                <div className={css.headerLink}>
                  <Person style={{ zoom: '150%' }} />
                  <div className={css.myAccount}>
                    {user ? (
                      <Link to={'/account'}>My Account</Link>
                    ) : (
                      <Link to={'/login'}>Login</Link>
                    )}
                  </div>
                  <div className={css.myBag}>
                    {cartItems?.length && <div className={css.bagCount}>
                      <span className={css.countLabel}>{cartItems?.length}</span>
                      </div>}
                    <Bag style={{ zoom: '120%' }} />
                  </div> */}
                {/* {isStoreAdmin && (
                    <div>
                      <Link to={'/admin'}>Store Admin</Link>
                    </div>
                  )} */}
                {/* {isSiteAdmin && (
                    <div>
                      <Link to={'/platform/admin'}>Site Admin</Link>
                    </div>
                  )} 
                </div>
                */}
              </Col>
              {/* <Col xs={2}>
                <div className={``}>
                  <Link to={'/cart'}><Bag /> ({cartItems?.length})</Link><br />
                  <Link to={'/orders'}>My Orders</Link>
                </div>
              </Col> */}
            </Row>
            <Row className="xs-only">
              <Col>
                <div>
                  <Searchbar />
                </div>
              </Col>
            </Row>
            <div className={css.categoryWrapper}>
              {mainNavPages.map((i) => {
                return (
                  <Link key={i.id} to={`/pages/${i.slug}`}>
                    <div className={css.category}>{i.name}</div>
                  </Link>
                );
              })}
              {topCategories.map((i) => {
                return (
                  <Link key={i.id} to={`/category/${i.shortId}`}>
                    <div
                      className={css.category}
                      onMouseLeave={(e) => {
                        this.timeout = setTimeout(() => {
                          this.setState({
                            openTopCategory: null,
                          });
                        }, 200);
                      }}
                      onMouseEnter={() => {
                        clearTimeout(this.timeout);
                        this.setState({
                          openTopCategory: i.id,
                        });
                      }}
                    >
                      {i.name}

                      {childCategories[i.id] && openTopCategory === i.id && (
                        <div className={css.subCategory}>
                          {childCategories[i.id].map((child) => {
                            return (
                              <Link key={child.id} to={`/category/${child.shortId}`}>
                                <div
                                  className={css.subCategoryItem}
                                  onMouseEnter={() => {
                                    clearTimeout(this.timeout);
                                    this.setState({
                                      openChildCategory: child.id,
                                    });
                                  }}
                                  onMouseLeave={() => {
                                    this.timeout = setTimeout(() => {
                                      this.setState({
                                        openChildCategory: null,
                                      });
                                    }, 200);
                                  }}
                                >
                                  {child.name}

                                  {childCategories[child.id] && (
                                    <span className={css.childCategoryArrow}>></span>
                                  )}
                                  {this.state.openChildCategory === child.id &&
                                    childCategories[this.state.openChildCategory] && (
                                      <div className={css.level3Cat}>
                                        {childCategories[this.state.openChildCategory].map(
                                          (cat2) => {
                                            return (
                                              <div className={css.level3CatItem} key={cat2.id}>
                                                <Link to={`/category/${cat2.shortId}`}>
                                                  {cat2.name}
                                                </Link>
                                              </div>
                                            );
                                          },
                                        )}
                                      </div>
                                    )}
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Link>
                );
              })}
              {showMembershipTopBar && <Link to="/memberships">
                <div className={css.category}>Membership</div>
              </Link>}
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapState = (state: IAppState) => {
  const { storePages = [], cartItems, user, store, storeAdmin, categories = [], memberships = [] } = state.app;
  const notHidden = categories.filter((i) => i.hidden !== true);
  const topCategories = notHidden.filter((i) => !i.parent);
  const childCategories = groupBy(notHidden, (c) => c.parent);
  const mainNavPages = storePages.filter(
    (a) => a?.settings?.showLink && !a.settings?.linkInCategoryId,
  );
  const categoryPages = storePages.filter(
    (a) => a?.settings?.showLink && a.settings?.linkInCategoryId > 0,
  );
  const pagesInCategoryLookup = groupBy(categoryPages, (cp) => cp.settings.linkInCategoryId);
  return {
    showMembershipTopBar: memberships?.length > 0,
    mainNavPages,
    pagesInCategoryLookup,
    user: user,
    cartItems,
    title: store?.name,
    logoUrl: store?.logoUrl,
    topCategories,
    childCategories,
    isSiteAdmin: user?.siteAdmin,
    isStoreAdmin: Boolean(
      storeAdmin && storeAdmin[store?.id] && storeAdmin[store?.id]?.level === 1,
    ),
  };
};
const mapDispatch = () => {
  return {};
};
export default connect(mapState, mapDispatch)(StoreHeader);
