import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LineChart, XAxis, Tooltip, CartesianGrid, Line } from 'recharts';
import { IProductDto } from '../../../../../server/src/dto/IProduct';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';

interface IProps {
  product: IProductDto;
  showHeader?: boolean;
  showLegend?: boolean;
  compareTo: IProductDto[];
}

const SpecList = ({ products, property, label, prefix = '' }) => {
  return (
    <tr>
      <td>{label}</td>
      {products.map((i: IProductDto) => {
        return (
          <td>
            {prefix}
            {i[property]}
          </td>
        );
      })}
    </tr>
  );
};

export const Specifications: React.FC<IProps> = ({
  product,
  compareTo = [],
  showHeader = true,
  showLegend = true,
}) => {
  if (!product || !product.specs || !product.specs.length || !product.showComparison) {
    return null;
  }
  const allProducts = [product, ...compareTo];
  return (
    <div>
      {showHeader && (
        <React.Fragment>
          <hr />
          <h4>Similar Items</h4>
          <div className="spacer"></div>
        </React.Fragment>
      )}
      <div style={{ width: '700px' }}>
        <Table striped size="sm" responsive>
          <thead>
            <td colSpan={1}></td>
            <td colSpan={1} width="150" height="150">
              {product.images[0] && (
                <ProductThumbnail
                  ext={product.images[0].ext}
                  shortId={product.images[0].shortId}
                  size="50"
                />
              )}
              {product.name}
            </td>
            {compareTo.map((compProduct) => {
              return (
                <td colSpan={1} width="150" height="150">
                  {compProduct.images[0] && (
                    <ProductThumbnail
                      ext={compProduct.images[0].ext}
                      shortId={compProduct.images[0].shortId}
                    />
                  )}
                  <Link to={`/product/${compProduct.slug || compProduct.shortId}`}>
                    {compProduct.name}
                  </Link>
                </td>
              );
            })}
          </thead>

          <SpecList products={allProducts} label={'Price'} property="price" prefix="$" />
          <SpecList products={allProducts} label={'Rating'} property="avgRating" />

          {product.specs
            .filter((i) => i.key[0] !== '_')
            .map((i) => {
              return (
                <tr>
                  <td>{i.key}</td>
                  <td>{i.value}</td>
                  {compareTo.map((compProduct) => {
                    return <td>{compProduct?.specs?.find((ii) => ii.key === i.key)?.value}</td>;
                  })}
                </tr>
              );
            })}

          <tr>
            <td>EI Profile</td>
            {allProducts.map((i) => {
              const _eiProfile = JSON.parse(i?.specs?.find(i => i.key === '_eiProfile')?.value || '[]');
              const data = [
                { name: 'a', x: 1, y: 10 },
                { name: 'b', x: 2, y: 20 },
                { name: 'c', x: 3, y: 30 },
              ];
              const data2 = _eiProfile.map(([x, y]) => ({
                x, y
              }));
              return (
                <td width={150} height={150}>
                  <LineChart
                    width={140}
                    height={140}
                    data={data2}
                  >
                    <Line dot={false} type="monotone" dataKey="y" stroke="#ff7300" yAxisId={0} />
                  </LineChart>
                </td>
              );
            })}
          </tr>
        </Table>
      </div>
    </div>
  );
};
