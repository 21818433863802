import React, { useCallback, useState, useEffect } from 'react';
import { keyBy } from 'lodash';
import moment from 'moment';
import { useDispatch, connect } from 'react-redux';
import { Input, Button, Card, CardBody, CardText, Container, Row, Col, Table } from 'reactstrap';
import Form from '@rjsf/core';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { setApp } from 'store/app/actions';
import {
  updateMyAccountApi,
  setAddressApi,
  deleteAddressApi,
  bootstrapApi,
  saveCategoryAdminApi,
  softDeleteCategoryAdminApi,
} from '../../api';
import { IAppState } from '../../store';
import { IUserDto, IUserAddressDto } from '../../../../../server/src/dto/IUser';
import css from './AdminCategories.module.css';
import { Link } from 'react-router-dom';
import AdminLinks from '../../components/AdminLinks/AdminLinks';
import { ICategoryDto } from '../../../../../server/src/dto/ICategory';
import Modal from '../../components/Modal/Modal';
import CheckComponent from 'components/CheckComponent/CheckComponent';

const AdminCategories: React.Component<any, any> = ({
  categories,
  categoryLookup,
}: {
  categories: ICategoryDto[];
  categoryLookup: { [catId: number]: ICategoryDto };
}) => {
  const dispatch = useDispatch();
  const [editingCategoryId, setIsEditing] = useState(-1);
  const [editingCategory, setEditingCategory] = useState({});
  useEffect(() => {
    setEditingCategory(categoryLookup[editingCategoryId] || {});
  }, [editingCategoryId]);

  return (
    <React.Fragment>
      <StoreHeader />
      {editingCategoryId > -1 && editingCategory && (
        <Modal
          onOutsideClick={() => {
            setIsEditing(-1);
          }}
        >
          <h1>Edit Category</h1>
          <Container>
            <div>
              Name: <br />
              <Input
                type="text"
                value={editingCategory.name}
                onChange={(e) => {
                  setEditingCategory({
                    ...editingCategory,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              URL Slug: <br />
              <Input
                type="text"
                value={editingCategory.shortId}
                onChange={(e) => {
                  setEditingCategory({
                    ...editingCategory,
                    shortId: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              Parent: <br />
              <Input
                type="select"
                value={editingCategory.parent}
                onChange={(e) => {
                  setEditingCategory({
                    ...editingCategory,
                    parent: e.target.value,
                  });
                }}
              >
                <option>None (root level)</option>
                {categories.map((cat) => {
                  return <option value={cat.id}>{cat.name}</option>;
                })}
              </Input>
            </div>
            <div className="spacer">
              <CheckComponent
                checked={editingCategory.hidden}
                label="Hide From Menu"
                onChange={(e) => {
                  const checked = e.target.checked ? true : false;
                  setEditingCategory({
                    ...editingCategory,
                    hidden: checked,
                  });
                }}
              />
            </div>
            <div className="spacer">
              <CheckComponent
                checked={editingCategory.featured}
                label="Featured Category"
                onChange={(e) => {
                  const checked = e.target.checked ? true : false;
                  setEditingCategory({
                    ...editingCategory,
                    featured: checked,
                  });
                }}
              />
            </div>
            <hr />
            <div>
              <Button
                onClick={async () => {
                  const cat = { ...editingCategory };
                  await saveCategoryAdminApi(cat);
                  setIsEditing(-1);
                  const data = await bootstrapApi();
                  dispatch(setApp(data));
                }}
              >
                Save
              </Button>
            </div>
          </Container>
        </Modal>
      )}
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col>
            <h1>Categories Admin</h1>
            <Button
              onClick={() => {
                setIsEditing(0);
              }}
            >
              Add New
            </Button>
            <div>
              <Table>
                <tr>
                  <th>Category Name</th>
                  <th>Parent Category</th>
                  <th>Created</th>
                  <th>Edit</th>
                </tr>
                <tbody>
                  {categories.map((cat) => {
                    return (
                      <tr key={cat.id}>
                        <td>
                          <div className={css.item}>{cat.name}</div>
                        </td>
                        <td>{categoryLookup[cat.parent]?.name || '<root level>'}</td>
                        <td>{moment(cat.createdAt).format('MM/DD/YYYY')}</td>
                        <td>
                          <Link
                            onClick={() => {
                              setIsEditing(cat.id);
                            }}
                          >
                            Edit
                          </Link>
                          <br />
                          <Link
                            onClick={async () => {
                              const resp = confirm(
                                'Are you sure you want to delete this category?',
                              );
                              if (resp) {
                                // todo delete
                                await softDeleteCategoryAdminApi({ id: cat.id });
                                const data = await bootstrapApi();
                                dispatch(setApp(data));
                              }
                            }}
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapState = (state: IAppState) => {
  const { app } = state;
  const { user, storeAdmin, store, categories } = app;
  const categoryLookup = keyBy(categories, (c) => c.id);
  return {
    user,
    categories,
    categoryLookup,
  };
};
export default connect(mapState, null)(AdminCategories);
