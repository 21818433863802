import React, { useCallback, useState, useEffect, useRef } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { get, orderBy, keyBy, uniq, groupBy } from 'lodash';
import { setWith } from 'lodash/fp';
import { useDispatch, connect } from 'react-redux';
import { Input, Button, Card, CardBody, CardText, Container, Row, Col, Table } from 'reactstrap';
import Form from '@rjsf/core';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { setApp } from 'store/app/actions';
import {
  deleteImagesApi,
  getProductsAdminApi,
  saveProductAdminApi,
  uploadImagesApi,
  copyProductAdminApi,
  uploadFilesApi,
  deleteFileApi,
} from '../../api';
import { IAppState } from '../../store';
import { IUserDto, IUserAddressDto } from '../../../../../server/src/dto/IUser';
import css from './AdminProducts.module.css';
import { Link } from 'react-router-dom';
import AdminLinks from '../../components/AdminLinks/AdminLinks';
import { ICategoryDto } from '../../../../../server/src/dto/ICategory';
import Modal from '../../components/Modal/Modal';
import { IProductDto } from '../../../../../server/src/dto/IProduct';
import DropUpload from './DropUpload';
import CheckComponent from '../../components/CheckComponent/CheckComponent';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import Loading from 'components/Loading/Loading';
import MyInput from 'components/MyInput';
import { isClient, getNewId, strStartCase } from 'utils';
import { EEntityType } from '../../../../../server/src/entity/store/generics';
import { useHistory } from 'react-router';
import { Membership } from '../../../../../server/src/entity/memberships/membership';
import product from 'pages/Product/product';

const fetch = async ({ dispatch, cb }) => {
  const results = await getProductsAdminApi();
  dispatch(
    setApp({
      products: results,
    }),
  );
  if (cb) {
    cb();
  }
};

let Editor;
if (isClient()) {
  import('react-draft-wysiwyg').then((e) => {
    Editor = e.Editor;
  });
}

const AdminProducts: React.Component<any, any> = ({
  memberships,
  products,
  productLookup,
  categoryLookup,
  categories,
  editingProductShortId,
}: {
  memberships: Membership[];
  products: IProductDto[];
  productLookup: { [productId: number]: IProductDto };
  categoryLookup: { [categoryId: number]: ICategoryDto };
  categories: ICategoryDto[];
  editingProductShortId?: string;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const variationRef = useRef();
  const [editingProduct, setEditingProduct] = useState({});
  const [attrsToAdd, setAttrsToAdd] = useState({});
  const [attrsList, setAttrsList] = useState([]);
  const [requiredVariations, setRequiredVariations] = useState({});
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [digitalProductFiles, setDigitalProductFiles] = useState([]);
  const [sortForImgId, setSortForImgId] = useState({});
  const [editingProductId, setIsEditing] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [digitalProductContentState, setDigitalProductContentState] = React.useState(
    EditorState.createEmpty(),
  );
  const [addonState, setaddonState] = React.useState({});
  const [requiredAddons, setRequiredAddons] = React.useState({});
  const [addonItems, setaddonItems] = React.useState([]);

  useEffect(() => {
    const toEditProduct = products.find((p) => p.shortId === editingProductShortId);
    if (toEditProduct) {
      setIsEditing(toEditProduct.id);
    }
  }, [editingProductShortId, products]);

  const addonsGrouped = groupBy(addonItems, (i) => i.title);
  useEffect(() => {
    fetch({ dispatch });
    // dev only
    // setTimeout(() => {
    //   setIsEditing(1);
    // }, 2000);
  }, []);

  useEffect(() => {
    setFilesToUpload([]);
    const product = productLookup[editingProductId];
    const toEditProduct = { ...product };
    if (toEditProduct?.description) {
      const editorState = EditorState.createWithContent(convertFromRaw(toEditProduct.description));
      setEditorState(editorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
    if (toEditProduct?.digitalProductDescription) {
      const editorState = EditorState.createWithContent(
        convertFromRaw(toEditProduct.digitalProductDescription),
      );
      setDigitalProductContentState(editorState);
    }
    if (toEditProduct && toEditProduct.specs && toEditProduct.specs.length) {
      toEditProduct.specs = orderBy(toEditProduct.specs, 'key');
    }
    setEditingProduct(toEditProduct || {});
    const sortDefaults = {};

    toEditProduct?.images?.forEach((img, ix) => {
      sortDefaults[img.id] = img.sortOrder || ix + 1;
    });
    setSortForImgId(sortDefaults);
    setAttrsList(toEditProduct?.attributes || []);
    const reqVars = product?.requiredAttributes?.reduce((acc, val) => {
      acc[val] = true;
      return acc;
    }, {});
    setRequiredVariations(reqVars || {});

    setaddonItems(toEditProduct?.addonItems || []);
    setRequiredAddons(toEditProduct?.requiredAddons || {});
  }, [editingProductId, productLookup]);

  const saveChanges = (key, checkbox = false) => (e: Event) => {
    if (checkbox === true) {
      const checked = e.target.checked ? true : null;
      setEditingProduct(setWith(editingProduct, key, checked));
      return;
    }
    setEditingProduct(setWith(editingProduct, key, e.target.value));
  };

  const editor = React.useRef(null);
  const editor2 = React.useRef(null);

  function focusEditor() {
    if (editor?.current && editor?.current.focus) {
      editor?.current?.focus();
    }
  }

  useEffect(() => {
    focusEditor();
  }, []);

  const toggleCategory = useCallback(
    (catId) => {
      const set = new Set(editingProduct.categories);
      if (set.has(catId)) {
        set.delete(catId);
      } else {
        set.add(catId);
      }

      setEditingProduct(setWith(editingProduct, 'categories', Array.from(set)));
    },
    [editingProduct],
  );

  const variationPermutations = [];
  attrsList.forEach((a) => {
    attrsList.forEach((b) => {
      if (a.key != b.key) {
        variationPermutations.push([a, b]);
      }
    });
  });

  return (
    <React.Fragment>
      <StoreHeader />
      {editingProductId > -1 && editingProduct && isLoading && (
        <Modal onOutsideClick={() => {}}>
          <Loading />
        </Modal>
      )}
      {editingProductId > -1 && editingProduct && !isLoading && (
        <Modal
          onOutsideClick={() => {
            setIsEditing(-1);
            history.replace('/admin/products');
          }}
        >
          <h1>Edit Product</h1>
          <Container>
            <div>
              Product Name: <br />
              <Input type="text" value={editingProduct.name} onChange={saveChanges('name')} />
            </div>
            <div>
              Brand: <br />
              <Input type="text" value={editingProduct.brand} onChange={saveChanges('brand')} />
            </div>
            <div>
              URL Slug: <br />
              <Input type="text" value={editingProduct.slug} onChange={saveChanges('slug')} />
            </div>
            <div>
              Price: <br />
              <Input type="text" value={editingProduct.price} onChange={saveChanges('price')} />
              <div style={{ margin: '5px' }}>
                <CheckComponent
                  checked={editingProduct.priceInCart}
                  onChange={saveChanges('priceInCart', true)}
                  label="Show Price In Cart Only"
                />{' '}
              </div>
            </div>
            <div>
              Quantity: <br />
              <Input
                type="text"
                value={editingProduct.quantity}
                onChange={saveChanges('quantity')}
              />
              <br />
              <div onClick={focusEditor}>
                Product Description:
                <div className={css.editor}>
                  {Editor && (
                    <Editor
                      ref={editor}
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                    />
                  )}
                </div>
              </div>
              <hr />
              <h4>Product Variations</h4>
              <div>
                <Row>
                  <Col>
                    Key
                    <MyInput
                      type="text"
                      setState={setAttrsToAdd}
                      path={'key'}
                      state={attrsToAdd}
                      innerRef={variationRef}
                    />
                  </Col>
                  <Col>
                    Value
                    <MyInput
                      type="text"
                      path={'value'}
                      state={attrsToAdd}
                      setState={setAttrsToAdd}
                    />
                  </Col>
                  <Col>
                    Price Delta (+/-)
                    <MyInput
                      type="text"
                      path={'price'}
                      state={attrsToAdd}
                      setState={setAttrsToAdd}
                    />
                  </Col>
                  <Col>
                    Qty
                    <MyInput type="text" path={'qty'} state={attrsToAdd} setState={setAttrsToAdd} />
                  </Col>
                  <Col>
                    <br />
                    <Button
                      onClick={() => {
                        const toAdd = {
                          ...attrsToAdd,
                          id: getNewId(),
                        };
                        const arr = [...attrsList, toAdd];
                        setAttrsList(arr);
                        setAttrsToAdd({});
                        if (variationRef.current) {
                          variationRef?.current?.focus();
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                <hr />
                {orderBy(attrsList, ['key', 'value']).map((a, ix, arr) => {
                  return (
                    <Row className="spacer" key={`${a.key}_${a.value}`}>
                      <Col>{strStartCase(a.key)}</Col>
                      <Col>
                        {strStartCase(a.value)}
                        <Input
                          type="select"
                          value={a.imageShortId}
                          onChange={(e) => {
                            const selectedImageId = e.target.value;
                            const newItem = {
                              ...a,
                              imageShortId: selectedImageId,
                            };
                            const newArray = attrsList.map((i) => (i === a ? newItem : i));
                            setAttrsList(newArray);
                          }}
                        >
                          <option>Select Img (optional)</option>
                          {(editingProduct?.images || []).map((img, ix) => {
                            return <option value={img.shortId}>{img.name}</option>;
                          })}
                        </Input>
                      </Col>
                      <Col>${a.price}</Col>
                      <Col>
                        <Input
                          type="text"
                          value={a.qty}
                          onChange={(e) => {
                            const qty = e.target.value;
                            const newItem = {
                              ...a,
                              qty,
                            };
                            const newArray = attrsList.map((i) => (i === a ? newItem : i));
                            setAttrsList(newArray);
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={() => {
                            const removed = attrsList.filter((i) => i !== a);
                            setAttrsList(removed);
                          }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <hr />
              <small>
                Required Variations: When a variation option is required, customers must select the
                option before checking out. Otherwise the variation is optional.
              </small>
              {uniq(attrsList.map((i) => i.key)).map((i) => {
                return (
                  <Row key={i}>
                    <Col>{i}</Col>
                    <Col>
                      <CheckComponent
                        label="Is Required"
                        checked={requiredVariations[i]}
                        onChange={() => {
                          if (requiredVariations[i]) {
                            delete requiredVariations[i];
                          } else {
                            requiredVariations[i] = true;
                          }
                          setRequiredVariations({ ...requiredVariations });
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
              <hr />
              <h4>Addon Options</h4>
              <Row>
                <Col sm={3}>
                  Addon Title:
                  <MyInput type="text" state={addonState} setState={setaddonState} path="title" />
                </Col>

                <Col sm={3}>
                  Select Product
                  <MyInput
                    type="select"
                    state={addonState}
                    setState={setaddonState}
                    path="productId"
                  >
                    <option>None</option>
                    {products.map((p) => {
                      return (
                        <option value={p.id}>
                          {p.name} (${p.price})
                        </option>
                      );
                    })}
                  </MyInput>
                </Col>
                <Col>
                  <br />
                  <Button
                    onClick={() => {
                      const addon = {
                        ...addonState,
                        id: getNewId(),
                      };
                      setaddonItems([...addonItems, addon]);
                      setaddonState({});
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <hr />
              <small>Here are your addons</small>
              <div>
                {Object.keys(addonsGrouped).map((key) => {
                  const value = addonsGrouped[key] || [];
                  return (
                    <Row className="spacer" key={`${key}_${value.length}`}>
                      <Col xs={3} className="border-bottom">
                        {key}
                        <br />
                        <CheckComponent
                          label="Required"
                          checked={requiredAddons[key]}
                          onChange={() => {
                            setRequiredAddons({
                              ...requiredAddons,
                              [key]: !requiredAddons[key],
                            });
                          }}
                        />
                      </Col>
                      <Col xs={6} className="border-bottom">
                        {value.map((v) => {
                          const p = productLookup[Number(v.productId)];
                          return (
                            <Row key={p.id}>
                              <Col>
                                {p.name} (${p.price})
                              </Col>
                              <Col>
                                <Link
                                  onClick={() => {
                                    const items = addonItems.filter((a) => a.id !== v.id);
                                    setaddonItems(items);
                                  }}
                                >
                                  Remove
                                </Link>
                              </Col>
                            </Row>
                          );
                        })}
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <hr />
              {/* <h4>Variation Quantity</h4>
              {variationPermutations.map((v) => {
                return (
                  <div>{v[0].key} {v[0].value} - {v[1].key} {v[1].value}</div>
                );
              })}
              <p>Set custom quantities based on your variations</p>
              <hr /> */}
            </div>
            <h4>Product Specifications</h4>
            <div>
              <Button
                size="sm"
                onClick={() => {
                  const oldSpecs = editingProduct.specs || [];
                  setEditingProduct({
                    ...editingProduct,
                    specs: [...oldSpecs, { key: '', value: '' }],
                  });
                }}
              >
                Add
              </Button>
              <br />
              <Table>
                <thead>
                  <td width="50%">Key</td>
                  <td width="50%">Value</td>
                  <td></td>
                </thead>
                {(editingProduct.specs || []).map((i, ix, arr) => {
                  return (
                    <tr>
                      <td width="50%">
                        <MyInput
                          type="text"
                          setState={(s) => {
                            const arrCopy = [...arr];
                            arrCopy[ix] = {
                              ...i,
                              key: s.key,
                            };
                            setEditingProduct({
                              ...editingProduct,
                              specs: arrCopy,
                            });
                          }}
                          path={'key'}
                          state={arr[ix]}
                        />
                      </td>
                      <td width="50%">
                        <MyInput
                          type="text"
                          setState={(s) => {
                            const arrCopy = [...arr];
                            arrCopy[ix] = {
                              ...i,
                              value: s.value,
                            };
                            setEditingProduct({
                              ...editingProduct,
                              specs: arrCopy,
                            });
                          }}
                          path={'value'}
                          state={arr[ix]}
                        />
                      </td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => {
                            const arrCopy = [];
                            arr.forEach((i2, ix2) => {
                              if (ix2 !== ix) {
                                arrCopy.push(i2);
                              }
                            });
                            setEditingProduct({
                              ...editingProduct,
                              specs: arrCopy,
                            });
                          }}
                        >
                          Del
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
            <hr />
            <h4>Settings</h4>
            <div>
              <CheckComponent
                checked={editingProduct.membersOnly}
                onChange={saveChanges('membersOnly', true)}
                label="Members Only Item"
              />{' '}
              <small>
                Show price to members only. Only allow it to be added to carts of subscribed
                members.
                <br />
              </small>
              {editingProduct.membersOnly && (
                <div
                  style={{
                    margin: '5px',
                    border: '1px solid #ddd',
                    marginBottom: '20px',
                  }}
                >
                  {(memberships || []).map((m) => {
                    return (
                      <Row>
                        <Col lg={2}>{m.name}</Col>
                        <Col lg={2}>
                          <CheckComponent
                            checked={get(editingProduct, `membershipMap.${m.shortId}`)}
                            onChange={saveChanges(`membershipMap.${m.shortId}`, true)}
                            label="Enabled"
                          />{' '}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              )}
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.showComparison}
                onChange={saveChanges('showComparison', true)}
                label="Show Comparison"
              />{' '}
              <small>
                Show item comparison at bottom, compared to other items in the same category
              </small>
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.quantityUnlimited}
                onChange={saveChanges('quantityUnlimited', true)}
                label="Is Unlimited?"
              />{' '}
              <small>
                Prevent this from being sold-out when quantity drops to 0.
                <br />
              </small>
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.addonOnly}
                onChange={saveChanges('addonOnly', true)}
                label="Addon Item Only:"
              />{' '}
              <small>
                This item wont appear in search or categories
                <br />
              </small>
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.digital}
                onChange={saveChanges('digital', true)}
                label="Digital Product?"
              />{' '}
              <small>
                Digital products wont need to be shipped, and you will need to deliver the product
                yourself.
              </small>
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.allowRefunds}
                onChange={saveChanges('allowRefunds', true)}
                label="Allow Refunds"
              />{' '}
              <small>Allow refunds / returns for orders with this item</small>
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.enabled}
                onChange={saveChanges('enabled', true)}
                label="Product Enabled"
              />{' '}
              <small>Disable product store wide</small>
            </div>
            <div>
              <CheckComponent
                checked={editingProduct.frontpage}
                onChange={saveChanges('frontpage', true)}
                label="Front Page Featured"
              />{' '}
              <small>Display this product on the landing page</small>
            </div>
            <hr />
            <div>
              <Row>
                <Col>
                  <h4>Images</h4>
                  <br />
                  <Row>
                    <Col>Sort Order</Col>
                    <Col>Image</Col>
                    <Col>Action</Col>
                  </Row>
                  {orderBy(editingProduct?.images || [], 'sortOrder').map((img, ix) => {
                    return (
                      <div key={img.id}>
                        <Row>
                          <Col>
                            <Input
                              style={{ width: '60px' }}
                              type="text"
                              maxLength={2}
                              value={sortForImgId[img.id]}
                              onChange={(e) => {
                                setSortForImgId({
                                  ...sortForImgId,
                                  [img.id]: e.target.value,
                                });
                              }}
                            />
                          </Col>
                          <Col>
                            <div style={{ width: '80px', height: 'auto ' }}>
                              <ProductThumbnail shortId={img.shortId} ext={img.ext} size="100" />
                            </div>
                          </Col>
                          <Col>
                            <Link
                              onClick={async () => {
                                await deleteImagesApi({
                                  imageIds: [img.id],
                                });
                                await fetch({ dispatch });
                              }}
                            >
                              Delete
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>
            <hr />
            <div>
              <Row>
                <Col>
                  <h4>Add Images</h4>
                  <DropUpload
                    onDrop={(files) => {
                      if (files.length === 0) {
                        setDigitalProductFiles([]);
                        return;
                      }
                      setFilesToUpload([...filesToUpload, ...files]);
                    }}
                  />
                </Col>
                <Col>
                  Links to existing images:
                  <br />
                  <Input type="text" value={editingProduct.img1} onChange={saveChanges('img1')} />
                  <Input type="text" value={editingProduct.img2} onChange={saveChanges('img2')} />
                  <Input type="text" value={editingProduct.img3} onChange={saveChanges('img3')} />
                  <Input type="text" value={editingProduct.img4} onChange={saveChanges('img4')} />
                </Col>
              </Row>
            </div>
            <br /> <br />
            {editingProduct.digital === true && (
              <div>
                <hr />
                <h4>Digital Product Details</h4>
                <p>
                  When customers purchase a digital product, they will have a dedicated page to view
                  the product. Please put the content you would like to show in this section and add
                  files below.
                </p>
                <h5>Purchase Page Content</h5>
                <div className={css.editor}>
                  {Editor && (
                    <Editor
                      ref={editor2}
                      editorState={digitalProductContentState}
                      onEditorStateChange={setDigitalProductContentState}
                    />
                  )}
                </div>

                <h5>Digital Product Files</h5>
                <Row>
                  <Col>
                    <DropUpload
                      onDrop={(files) => {
                        if (files.length === 0) {
                          setDigitalProductFiles([]);
                          return;
                        }
                        setDigitalProductFiles([...digitalProductFiles, ...files]);
                      }}
                    />
                  </Col>
                  <Col>
                    <b>Existing Files</b>
                    <div>
                      {(editingProduct.files || []).map((f) => {
                        return (
                          <Row key={f.id} style={{ borderBottom: '1px solid #ccc' }}>
                            <Col>
                              <a target="_blank" href={f.signedUrl}>
                                {f.name}
                              </a>
                            </Col>
                            <Col>
                              <Link
                                onClick={async () => {
                                  await deleteFileApi(f.id);
                                  await fetch({ dispatch });
                                }}
                              >
                                Delete
                              </Link>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            <hr />
            <div>
              <h4>Categories</h4>
              <br />
              {categories.map((cat) => {
                return (
                  <div key={cat.id}>
                    <CheckComponent
                      checked={editingProduct?.categories?.includes(cat.id)}
                      onChange={() => toggleCategory(cat.id)}
                      label={cat.name}
                    />
                  </div>
                );
              })}
            </div>
            <hr />
            <div>
              <Button
                disabled={isLoading}
                onClick={async () => {
                  try {
                    const rawDescription = convertToRaw(editorState.getCurrentContent());
                    const digitalProductDescription = convertToRaw(
                      digitalProductContentState.getCurrentContent(),
                    );
                    const props = {
                      ...editingProduct,
                      description: rawDescription,
                      digitalProductDescription,
                      id: undefined,
                    };
                    if (editingProductId > 0) {
                      props.id = editingProductId;
                    }
                    setIsLoading(true);
                    const { productId } = await saveProductAdminApi({
                      product: props,
                      sortForImgId,
                      attrsList,
                      requiredVariations,
                      addonItems,
                      requiredAddons,
                    });
                    if (filesToUpload.length) {
                      const formData = new FormData();
                      formData.append('entityId', productId);
                      formData.append('entityType', EEntityType.PRODUCT.toString());
                      for (let i = 0; i < filesToUpload.length; i++) {
                        const value = filesToUpload[i];
                        formData.append('image', value);
                      }
                      await uploadImagesApi({ formData });
                    }
                    // digitalProductFiles
                    if (digitalProductFiles.length) {
                      const formData = new FormData();
                      formData.append('entityId', productId);
                      formData.append('entityType', EEntityType.PRODUCT.toString());
                      for (let i = 0; i < digitalProductFiles.length; i++) {
                        const value = digitalProductFiles[i];
                        formData.append('data', value);
                      }
                      console.log('formData', formData);
                      await uploadFilesApi({ formData });
                    }
                  } catch (err) {
                    console.error(err);
                  }
                  await fetch({ dispatch });
                  setIsEditing(-1);
                  history.replace('/admin/products');
                  setIsLoading(false);
                }}
              >
                Save
              </Button>
            </div>
          </Container>
        </Modal>
      )}
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col>
            <h1>Products Admin</h1>
            <Button
              onClick={() => {
                setIsEditing(0);
              }}
            >
              Add New
            </Button>
            <div>
              <Table>
                <tr>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Stats</th>
                  <th>Qty</th>
                  <th>Edit</th>
                </tr>
                <tbody>
                  {products.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className={css.item}>
                            {item.brand && <div>{item.brand}</div>}
                            <Link to={`/product/${item.slug || item.shortId}`}>{item.name}</Link>
                            <div>
                              {item.images[0] && (
                                <div style={{ width: '80px', height: 'auto' }}>
                                  <ProductThumbnail
                                    shortId={item?.images[0]?.shortId}
                                    ext={item?.images[0]?.ext}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className={css.item}>
                            {item.categories
                              ?.map((catId) => {
                                return categoryLookup[catId]?.name;
                              })
                              .join(', ')}
                          </div>
                        </td>

                        <td>
                          <div className={css.item}>${item.price}</div>
                          {item.priceInCart === true &&
                            <div>In Cart Only</div>
                          }
                        </td>

                        <td>
                          Views: {item.views}
                          <br />
                          In Cart: __
                          <br />
                          Sold: {item.sold}
                          <br />
                        </td>

                        <td>
                          <div className={css.item}>
                            {item.quantity === null ? 'Unlimited' : item.quantity}
                          </div>
                        </td>

                        <td>
                          <div className={css.item}>
                            <Link
                              onClick={async () => {
                                // setIsEditing(item.id);
                                history.push(`/admin/products/${item.shortId}`);
                              }}
                            >
                              Edit
                            </Link>
                            <br />
                            <Link
                              onClick={async () => {
                                await copyProductAdminApi({
                                  productId: item.id,
                                });
                                await fetch({ dispatch });
                              }}
                            >
                              Copy
                            </Link>
                            <br />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapState = (state: IAppState, props) => {
  const { app } = state;
  const { location, match } = props;
  const { user, storeAdmin, store, products = [], categories, memberships = [] } = app;
  const categoryLookup = keyBy(categories, (c) => c.id);
  const productLookup = keyBy(products, (p) => p.id);
  const editingProductShortId = get(props, 'match.params.shortId');
  console.log('editingProductShortId', editingProductShortId);
  return {
    memberships,
    user,
    products,
    productLookup,
    categoryLookup,
    categories,
    editingProductShortId,
  };
};
export default connect(mapState, null)(AdminProducts);
