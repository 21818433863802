import React, { useEffect, useCallback, useRef } from 'react';
import Icon from '../../assets/ball-triangle.svg';
import css from './Loading.module.css';

const Loading = (props) => {
  return (
    <div className={css.loading}>
      <div>Loading</div>
      <img src={Icon} className={css.img} />
    </div>
  );
};

export default Loading;
