import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { startCase, range, last } from 'lodash';
import { Link } from 'react-router-dom';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import Form from '@rjsf/core';
import { setWith } from 'lodash/fp';
import TextareaAutosize from 'react-textarea-autosize';
import { withRouter, useHistory } from 'react-router';
import { Row, Col, Container, Button, Input, Table, Card, CardBody, CardText } from 'reactstrap';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Archive, Square, XSquare } from 'react-bootstrap-icons';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import { IAppState } from '../../store';
import { IProductDto } from '../../../../../server/src/dto/IProduct';
import {
  addCardApi,
  removeFromCartApi,
  updateCartApi,
  fetchCartApi,
  submitOrderViaCardApi,
  getCardsApi,
  bootstrapApi,
  discountLookupApi,
  submitOrderViaPaypal,
} from '../../api';
import { setApp } from '../../store/app/actions';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import css from './Cart.module.css';
import { Cart } from '../../../../../server/src/entity/store/cart';
import { AppState } from 'store/app/types';
import { UserAddress } from '../../../../../server/src/entity/userAddress';
import { Store } from '../../../../../server/src/entity/store/store';
import { addressSchema } from 'pages/Account/account';
import MyInput from 'components/MyInput';
import './stripe.css';
import Modal from 'components/Modal/Modal';
import { isClient, getItemPrice, strStartCase, notify } from 'utils';
import { EDiscountType } from '../../../../../server/src/entity/store/enums';
import MyLoader from 'components/MyLoader/MyLoader';

interface IProps {
  items: Cart[];
  addresses: UserAddress[];
  store: Store;
}

enum EPayMethod {
  NONE = 0,
  CREDIT_CARD = 1,
  PAYPAL = 2,
  AMAZON = 3,
  APPLE = 4,
  ACH = 5,
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const validateAddress = ({ selectedAddress, newAddress, showNewAddress }) => {
  if (selectedAddress) {
    return true;
  }

  if (showNewAddress) {
    // validate new address
    if (!newAddress.name) {
      alert('Please fill out your shipping name');
      return false;
    }
    if (!newAddress.add1) {
      alert('Please fill out your shipping address');
      return false;
    }
    if (!newAddress.city) {
      alert('Please fill out your shipping city');
      return false;
    }
    if (!newAddress.state) {
      alert('Please fill out your shipping state');
      return false;
    }

    if (!newAddress.zip) {
      alert('Please fill out your shipping zip code');
      return false;
    }
    if (!newAddress.phone) {
      alert('Please fill out your shipping phone number');
      return false;
    }
    return true;
  }

  alert('Please select a shipping address');
  return false;
};

const StripeCheckout = ({ onSuccess, isLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      setError(result.error.message);
      console.log(result.error.message);
    } else if (result.token) {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      const token = result.token;
      onSuccess(token);
    }
  };
  return (
    <div>
      {error && (
        <Modal
          onOutsideClick={() => {
            setError(null);
          }}
        >
          <h1>Error</h1>
          {error}
          <p>Please try again</p>
        </Modal>
      )}
      <form onSubmit={handleSubmit}>
        <label>Credit Card Details</label>
        <p>You can pay via credit card below</p>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <br />
        <Button className="btn-success" disabled={!stripe || isLoading}>
          Confirm order
        </Button>
        {isLoading && <MyLoader />}
      </form>
    </div>
  );
};

const CartPage: React.FC<IProps> = ({ user, cards = [], store, addresses, items = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [paypalLoaded, setPaypalLoaded] = useState(0);
  const [stateTotal, setStateTotal] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState(EPayMethod.NONE);
  const [cardId, setCardId] = useState(null);
  const [guest, setGuest] = useState({});
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({});
  const [creditCard, setCreditCard] = useState({});
  const [selectedAddress, setAddress] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [editAddressId, setEditAddressId] = useState(null);
  const [comment, setComment] = useState('');
  const [settings, setSettings] = useState('');

  const [cartItems, setItems]: [Cart[], Function] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);

  const stateRef = useRef({});
  stateRef.current = {
    stateTotal,
    selectedAddress,
    newAddress,
    comment,
    settings,
    cartItems,
  };

  useEffect(() => {
    setItems(items);

    if (isClient()) {
      if (store.stripePublicKey) {
        import('@stripe/stripe-js').then((s) => {
          setStripePromise(s.loadStripe(store.stripePublicKey));
        });
      }
      if (store.paypalClientId) {
        if (!paypalLoaded) {
          setPaypalLoaded((s) => s + 1);
          const script = document.createElement('script');
          script.src = 'https://www.paypal.com/sdk/js?client-id=' + store.paypalClientId;
          document.body.appendChild(script);
          script.onload = () => {
            setPaypalLoaded((s) => s + 1);
          };
        }
      }
    }
  }, [items, store]);

  const subTotal = cartItems.reduce((acc, i) => {
    return acc + getItemPrice(i);
  }, 0);
  let total = Number(subTotal);
  if (store?.freeShipping) {
    // noop
  } else if (store?.flatShippingPrice > 0) {
    total += Number(store.flatShippingPrice);
  }

  if (settings.discount) {
    if (settings.discount.discountType === EDiscountType.DOLLARS) {
      total -= settings.discount.discountAmount;
    } else if (settings.discount.discountType === EDiscountType.PERCENT) {
      total -= (settings.discount.discountAmount / 100) * total;
    }
  }
  useEffect(() => {
    setStateTotal(total);
  }, [total]);

  const submitPaymentViaCard = async (stripeToken) => {
    if (!validateAddress({ newAddress, selectedAddress, showNewAddress })) {
      return;
    }

    if (!user) {
      alert('You must be logged in to checkout');
    }

    setLoading(true);
    try {
      await submitOrderViaCardApi({
        selectedAddress,
        newAddress,
        cardId,
        userTotal: Number(total),
        cartItemIds: items.map((i) => i.id),
        stripeToken,
        comment,
        discount: settings.discount,
      });
    } catch (err) {
      console.error(err);
    }
    const data = await bootstrapApi();
    dispatch(setApp(data));
    history.push('/orders');
    setLoading(false);
  };

  useEffect(() => {
    if (window.paypal) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            setLoading(true);
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: stateRef.current.stateTotal.toFixed(2),
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(async (details) => {
              try {
                await submitOrderViaPaypal({
                  payload: details,
                  selectedAddress: stateRef.current.selectedAddress,
                  newAddress: stateRef.current.newAddress,
                  userTotal: Number(stateRef.current.stateTotal),
                  cartItemIds: stateRef.current.cartItems.map((i) => i.id),
                  comment: stateRef.current.comment,
                  discount: stateRef.current.settings.discount,
                });
                const data = await bootstrapApi();
                dispatch(setApp(data));
                history.push('/orders');
                setLoading(false);
              } catch (err) {
                notify({
                  error: true,
                  message: err,
                });
              }
            });
          },
        })
        .render('#paypal-button-container');
    }
  }, [paypalLoaded]);

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h1>My Cart</h1>
        {items.length === 0 && (
          <Row className="spacer">
            <Col>You don't have anything in your cart currently. Go shopping!</Col>
          </Row>
        )}
        {items.length > 0 && (
          <React.Fragment>
            <Table>
              <tr>
                <th style={{ width: '10px' }}></th>
                <th style={{ width: '300px' }}>Product</th>
                <th>Details</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
              <tbody>
                {cartItems.map((item, ix) => {
                  let totalPrice = item.price;
                  Object.values(item.attributes || {}).forEach((attr) => {
                    if (attr.price) {
                      totalPrice += Number(attr.price);
                    }
                  });
                  return (
                    <tr key={item.id}>
                      <td>
                        <XSquare
                          className={css.remove}
                          onClick={async () => {
                            const removed = confirm('Are you sure you want to remove item?');
                            if (removed) {
                              // td
                              await removeFromCartApi({ itemId: item.id });
                              const { items } = await fetchCartApi();
                              dispatch(
                                setApp({
                                  cartItems: items,
                                }),
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <div style={{ width: '100px', float: 'left' }}>
                          {item.product.images &&
                            (() => {
                              const imgAttr = (Object.values(item.attributes) || []).find(
                                (a) => !!a.imageShortId,
                              );
                              let img = item.product.images[0];
                              if (imgAttr) {
                                const maybeImg = item.product.images.find(
                                  (a) => a.shortId === imgAttr.imageShortId,
                                );
                                if (maybeImg) {
                                  img = maybeImg;
                                }
                              }
                              return <ProductThumbnail ext={img?.ext} shortId={img?.shortId} />;
                            })()}
                        </div>
                        <div style={{ paddingLeft: '20px', display: 'inline-block' }}>
                          <Link to={`/product/${item.product.slug || item.product.shortId}`}>
                            {item.product.name}
                          </Link>
                          <br />
                          {item.product.brand}
                        </div>
                      </td>
                      <td>
                        {Object.keys(item?.attributes || {}).map((a) => {
                          let costString = '';
                          if (item.attributes[a].price > 0) {
                            costString = `(+$${item.attributes[a].price})`;
                          } else if (item.attributes[a].price < 0) {
                            costString = `(-$${item.attributes[a].price * -1})`;
                          }
                          return (
                            <div key={a}>
                              {strStartCase(a)}: {strStartCase(item.attributes[a].value)}{' '}
                              {costString}
                            </div>
                          );
                        })}
                      </td>
                      <td
                        width="20"
                        style={{
                          width: '20px',
                        }}
                      >
                        <Input
                          value={item.quantity}
                          type="select"
                          onChange={async (e) => {
                            // td update cart api
                            const quantity = e.target.value;
                            await updateCartApi({ itemId: item.id, quantity });
                            setItems(setWith(cartItems, [ix, 'quantity'], quantity));
                          }}
                        >
                          {range(1, 10).map((i) => {
                            return (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            );
                          })}
                        </Input>
                      </td>
                      <td
                        style={{
                          width: '50px',
                        }}
                      >
                        ${getItemPrice(item)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2}></td>
                  <td>Subtotal:</td>
                  <td>${subTotal.toFixed(2)}</td>
                </tr>

                <tr>
                  <td colSpan={2}></td>
                  <td>Shipping:</td>
                  <td>
                    {store?.freeShipping === true && 'Free'}
                    {!store?.freeShipping &&
                      store?.flatShippingPrice > 0 &&
                      '$' + store?.flatShippingPrice?.toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}></td>
                  <td>
                    Discount Code:
                    <div>
                      {settings.codeNotFound && 'Not Found'}
                      <br />
                      <MyInput
                        style={{ width: '150px', display: 'inline-block' }}
                        path="discountCode"
                        state={settings}
                        setState={setSettings}
                      />{' '}
                      <Button
                        style={{ display: 'inline-block' }}
                        onClick={async () => {
                          setSettings((s) => ({
                            ...s,
                            codeNotFound: undefined,
                          }));
                          const { result } = await discountLookupApi({
                            code: settings.discountCode,
                          });
                          if (result) {
                            const {
                              code,
                              createdAt,
                              discountAmount,
                              discountType, // : "PERCENT"
                              validUntil,
                            } = result;
                            setSettings((s) => ({
                              ...s,
                              discount: result,
                            }));
                          } else {
                            setSettings((s) => ({
                              ...s,
                              codeNotFound: true,
                            }));
                          }
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </td>
                  <td>
                    {settings.discount && (
                      <div>
                        {settings.discount.discountType === EDiscountType.DOLLARS && '$'}
                        {settings.discount.discountAmount}
                        {settings.discount.discountType === EDiscountType.PERCENT && '%'} OFF
                      </div>
                    )}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}></td>
                  <td>Total:</td>
                  <td>${total.toFixed(2)}</td>
                </tr>
              </tbody>
            </Table>
            {!user && (
              <React.Fragment>
                <Row>
                  <Col>
                    <div>
                      You must <Link to={'/login'}>Login</Link> to checkout.
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {user && (
              <React.Fragment>
                <Row>
                  <Col>
                    <h4>Shipping Address</h4>
                    <div>
                      {(addresses || []).map((i) => {
                        return (
                          <Card
                            className="sm-margin hover-pointer"
                            key={i.id}
                            onClick={(e) => {
                              setAddress(i.id);
                              setShowNewAddress(false);
                            }}
                          >
                            <CardBody>
                              <CardText>
                                <Row>
                                  <Col>
                                    <div className={css.address}>
                                      <div>{i.name}</div>
                                      <div>{i.add1}</div>
                                      {i.add2 && <div>{i.add2}</div>}
                                      <div>
                                        {i.city}, {i.state} {i.zip}
                                      </div>
                                      {i.phone && <div>{i.phone}</div>}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <Input
                                      name={i.id}
                                      checked={selectedAddress === i.id ? true : false}
                                      type="radio"
                                      onClick={(e) => {}}
                                    />
                                  </Col>
                                </Row>
                              </CardText>
                            </CardBody>
                          </Card>
                        );
                      })}
                      <Card
                        className="sm-margin hover-pointer"
                        onClick={() => {
                          setAddress(null);
                          setShowNewAddress(true);
                        }}
                      >
                        <CardBody>
                          <CardText>
                            <Row>
                              <Col>
                                <div className={css.address}>
                                  <div>Add New Address</div>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <Input
                                  name="-1"
                                  checked={showNewAddress}
                                  type="radio"
                                  onClick={(e) => {}}
                                />
                              </Col>
                            </Row>
                          </CardText>
                        </CardBody>
                      </Card>
                    </div>
                    <hr />
                    {showNewAddress && (
                      <div>
                        <Card>
                          <CardBody>
                            {typeof window !== 'undefined' && (
                              <Form
                                schema={addressSchema}
                                formData={newAddress}
                                onChange={({ formData }) => {
                                  setNewAddress(formData);
                                }}
                                onSubmit={async ({ formData }) => {}}
                              >
                                <div></div>
                              </Form>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} sm={6}>
                    <div>
                      <h4>Order Comments</h4>
                      <small>Feel free to leave us a comment about your order </small>
                      <TextareaAutosize
                        style={{ width: '100%' }}
                        minRows={2}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <div style={{ display: !selectedAddress && !newAddress.name ? 'none' : 'block' }}>
                  <Row>
                    <Col xs={12} sm={6}>
                      <div>
                        <h4>Payment Method</h4>
                        {stripePromise && (
                          <div className="spacer stripeContainer" style={{ maxWidth: '400px' }}>
                            <Elements stripe={stripePromise}>
                              <StripeCheckout
                                isLoading={isLoading}
                                onSuccess={submitPaymentViaCard}
                              />
                            </Elements>
                          </div>
                        )}
                        <div className="spacer"></div>
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      {!!store.paypalClientId && <p>You can pay via Paypal below:</p>}
                      <div className={css.paypalArea}>
                        <div id="paypal-button-container"></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};
const mapState = ({ app }: IAppState) => {
  const { user, cards, cartItems, addresses, store } = app;
  return {
    cards,
    items: cartItems,
    addresses,
    store,
    user,
  };
};

export default connect(mapState, null)(CartPage);
