import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Button } from 'reactstrap';
import { IAppState } from 'store';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import MyInput from 'components/MyInput';
import * as api from 'api';
import { notify } from 'utils';

const CustomPage = ({}) => {
  const [state, setState] = useState({});
  const reset = async () => {
    await api.resetPasswordApi({ email: state.email });
    notify({
      message: 'Please check your email for the reset link',
    });
  };
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h3>Reset Password</h3>
        <p>Enter your email address and we will send you instructions on how to reset your password.</p>
        <div style={{ width: '300px'}}>
          <MyInput path="email" setState={setState} state={state} />
        </div>
        <div className="spacer">
          <Button onClick={reset}>Reset Password</Button>
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState, ownProps) => {
  const { location } = ownProps;
  const { match, pathname } = location;

  return {};
};

export default connect(mapState)(CustomPage);
