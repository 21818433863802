import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { first, startCase } from 'lodash';
import { useDispatch, connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardText,
  Container,
  Row,
  Col,
  Table,
  Input,
  Collapse,
} from 'reactstrap';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { IAppState } from '../../store';
import css from './AdminOrders.module.css';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import {
  getOrdersAdminApi,
  refundOrderAdminApi,
  captureOrderAdminApi,
  shipOrderAdminApi,
} from 'api';
import { setApp } from 'store/app/actions';
import AdminProducts from 'pages/AdminProducts/AdminProducts';
import { Cart } from '../../../../../server/src/entity/store/cart';
import { StorePurchase } from '../../../../../server/src/entity/store/storePurchase';
import Loading from 'components/Loading/Loading';
import { notify, strStartCase } from 'utils';
import MyInput from 'components/MyInput';
import { Link } from 'react-router-dom';

const fetch = async ({ dispatch }) => {
  const { orders } = await getOrdersAdminApi();
  dispatch(
    setApp({
      orders,
    }),
  );
};

const AdminProductsTableList = ({ products = [] }) => {
  return (
    <div>
      <ol
        style={{
          margin: '0',
          padding: '0',
        }}
      >
        {products.map((item: Cart, ix) => {
          return (
            <li key={item.id}>
              {item.product.name}
              <br />
              {Object.values(item.attributes || {}).map((attr) => {
                return (
                  <div key={attr.key}>
                    {strStartCase(attr.key)}: {attr.value}
                  </div>
                );
              })}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

const AdminOrders: React.FC<any> = ({ orders = [] }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [shippingState, setShippingState] = useState({});
  const [showDetails, setShowDetails] = useState({});

  useEffect(() => {
    fetch({ dispatch });
  }, []);
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col xs={10}>
            <h1>Admin: Orders</h1>
            <p>
              Here are your customers orders. When orders are received, there are a couple required
              actions.
            </p>
            <ol>
              <li>
                First, you must Capture the payment, or Cancel the order
                <br />
                <small>
                  If you cancel, no other steps are required and the hold on the credit card will be
                  removed
                </small>
              </li>
              <li>
                After capturing the payment, you will need to ship the order and provide the
                tracking number
              </li>
            </ol>
            <Table>
              <tr>
                <th>Date</th>
                <th>Customer</th>
                <th>Products</th>
                <th>Payment</th>
                <th>Next Steps</th>
              </tr>
              <tbody>
                {orders.map((order: StorePurchase) => {
                  return (
                    <tr key={order.id}>
                      <td>
                        {moment(order.createdAt).format('MM/DD/YYYY h:mma')}
                        <div>
                          <small>id: {order.shortId}</small>
                          <br />
                          <Link
                            onClick={() => {
                              if (!showDetails[order.id]) {
                                setShowDetails({
                                  ...showDetails,
                                  [order.id]: true,
                                });
                              } else {
                                setShowDetails({
                                  ...showDetails,
                                  [order.id]: undefined,
                                });
                              }
                            }}
                          >
                            <small>Show Details</small>
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>{order.user.name}</div>
                        <div>{order.user.email}</div>
                        <div>
                          <Collapse isOpen={showDetails[order.id]}>
                            Ship to:
                            <br />
                            <small>
                              {order.add1}
                              <br />
                              {order.add2 && <div>{order.add2}</div>}
                              {order.city}, {order.state} {order.zip}
                              <br />
                              {order.phone}
                            </small>
                            <br />
                            <hr />
                            Comments: <small>{order.comment}</small>
                          </Collapse>
                        </div>
                      </td>
                      <td>
                        <AdminProductsTableList products={order.cartItems} />
                      </td>
                      <td>
                        ${order.total}
                        <br />
                        {order.stripeCard?.payload.brand}
                        <br />
                        {order.stripeCard?.payload.last4}
                      </td>
                      <td>
                        {order.cancelledAt && <div>Cancelled</div>}
                        {!order.capturedAt && !order.cancelledAt && (
                          <React.Fragment>
                            <div>
                              <Button
                                disabled={loading}
                                onClick={async () => {
                                  setLoading(true);
                                  try {
                                    const { error } = await captureOrderAdminApi({
                                      shortId: order.shortId,
                                    });
                                    await fetch({ dispatch });
                                  } catch (err) {
                                    notify({
                                      error: true,
                                      message: err.message,
                                    });
                                  }
                                  setLoading(false);
                                }}
                              >
                                Capture Charge
                              </Button>
                            </div>
                            <div className="spacer">
                              <Button
                                disabled={loading}
                                onClick={async () => {
                                  try {
                                    const confirmed = confirm('Are you sure?');
                                    if (!confirmed) {
                                      return;
                                    }
                                    setLoading(true);
                                    const { error } = await refundOrderAdminApi({
                                      shortId: order.shortId,
                                    });
                                    await fetch({ dispatch });
                                    if (error) {
                                      throw new Error(error);
                                    }
                                    notify({
                                      message: 'Refund successful',
                                    });
                                  } catch (err) {
                                    notify({
                                      error: true,
                                      message: err.message,
                                    });
                                  }
                                  setLoading(false);
                                }}
                              >
                                Cancel Order
                              </Button>
                            </div>
                          </React.Fragment>
                        )}

                        {order.shippedAt && (
                          <div>
                            Order Shipped
                            {order.shippingRecords && order.shippingRecords[0] && (
                              <div>
                                <small>
                                  {order.shippingRecords[0].carrier}:{' '}
                                  {order.shippingRecords[0].trackingId}
                                </small>
                              </div>
                            )}
                            <div className="spacer">
                              <Button
                                disabled={loading}
                                onClick={async () => {
                                  try {
                                    const confirmed = confirm('Are you sure?');
                                    if (!confirmed) {
                                      return;
                                    }
                                    setLoading(true);
                                    const { error } = await refundOrderAdminApi({
                                      shortId: order.shortId,
                                    });
                                    await fetch({ dispatch });
                                    if (error) {
                                      throw new Error(error);
                                    }
                                    notify({
                                      message: 'Refund successful',
                                    });
                                  } catch (err) {
                                    notify({
                                      error: true,
                                      message: err.message,
                                    });
                                  }
                                  setLoading(false);
                                }}
                              >
                                Refund
                              </Button>
                            </div>
                          </div>
                        )}

                        {!order.cancelledAt && order.capturedAt && !order.shippedAt && (
                          <React.Fragment>
                            <div>
                              <MyInput
                                type="select"
                                setState={setShippingState}
                                state={shippingState}
                                path="shipperName"
                              >
                                <option>Select Shipper</option>
                                <option value={'UPS'}>UPS</option>
                                <option value={'USPS'}>USPS</option>
                                <option value={'FEDEX'}>FedEx</option>
                                <option value={'DHL'}>DHL</option>
                              </MyInput>
                              <MyInput
                                className="spacer"
                                type="text"
                                placeholder="tracking no"
                                path="trackingId"
                                setState={setShippingState}
                                state={shippingState}
                              />
                              <Button
                                disabled={loading}
                                className="spacer"
                                onClick={async () => {
                                  setLoading(true);
                                  try {
                                    await shipOrderAdminApi({
                                      shipperName: shippingState.shipperName,
                                      trackingId: shippingState.trackingId,
                                      shortId: order.shortId,
                                    });
                                    await fetch({ dispatch });
                                  } catch (err) {
                                    notify({
                                      error: true,
                                      message: err.toString(),
                                    });
                                  }
                                  setLoading(false);
                                }}
                              >
                                Mark Shipped
                              </Button>
                            </div>
                          </React.Fragment>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState) => {
  const { orders } = app;
  return {
    orders,
  };
};

export default connect(mapState, null)(AdminOrders);
