import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import { isClient, notify } from 'utils';
import { IAppState } from 'store';
import CheckComponent from 'components/CheckComponent/CheckComponent';
import {
  EContentType,
  ECustomPageContentNames,
} from '../../../../../server/src/entity/store/enums';
import * as api from '../../api';

import { StorePage } from '../../../../../server/src/entity/store/storePage';
import { StoreContent } from '../../../../../server/src/entity/store/content';
import { Store } from '../../../../../server/src/entity/store/store';
import MyInput from 'components/MyInput';
import { setApp } from 'store/app/actions';

let Editor;
if (isClient()) {
  import('react-draft-wysiwyg').then((e) => {
    Editor = e.Editor;
  });
}

interface IProps {
  slug: string;
  page: StorePage;
  content: StoreContent;
  store: Store;
}

const AdminEditCustomPage: React.FC<IProps> = ({ slug, page, content, store }) => {
  const dispatch = useDispatch();
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [settingsState, setSettingsState] = useState({});

  const [update, shouldUpdate] = useState(0);
  useEffect(() => {
    if (page) {
      setSettingsState({
        showLink: page.settings?.showLink,
        name: page.name,
      });
    }
  }, [page]);

  useEffect(() => {
    if (isClient()) {
      if (content) {
        setContentState(EditorState.createWithContent(convertFromRaw(content.richContent)));
      } else {
        setContentState(EditorState.createEmpty());
      }
    }
  }, [content]);

  setTimeout(() => {
    shouldUpdate(1);
  }, 100);

  const saveContent = async () => {
    const payload = convertToRaw(contentState.getCurrentContent());
    await api.setContentPayloadApi({
      payload,
      pageId: page.id,
      contentType: EContentType.RICH_TEXT,
      contentName: ECustomPageContentNames.CUSTOM_PAGE_RICH_TEXT_MAIN,
      pageSettings: settingsState,
    });
    const data = await api.bootstrapApi();
    dispatch(setApp(data));
    notify({
      message: 'Saved',
    });
  };
  return (
    <div>
      Edit Custom page: {page.name}
      <div className="spacer">
        Public Url:{' '}
        <Link to={`/pages/${slug}`}>
          https://{store.host}/pages/{slug}
        </Link>
      </div>
      <div className="spacer">
        <label>Rename Page</label>
        <MyInput path="name" setState={setSettingsState} state={settingsState} />
      </div>
      <div className="spacer">
        <CheckComponent
          label="Show Page in Menu"
          checked={settingsState.showLink}
          checkChange={(checked) => {
            setSettingsState({
              ...settingsState,
              showLink: checked,
            });
          }}
        />
      </div>
      <div className="spacer"></div>
      <div className="border spacer">
        {Editor && <Editor editorState={contentState} onEditorStateChange={setContentState} />}
      </div>
      <div className="spacer">
        <Button onClick={saveContent}>Save Page</Button>
      </div>
    </div>
  );
};

const mapState = ({ app }: IAppState, ownProps) => {
  const { slug } = ownProps;
  const { contentForPage, storePages, store } = app;
  const page = storePages.find((p) => p.slug === slug);
  const pageContent = contentForPage[page?.id]?.find(
    (i) =>
      i.contentType === EContentType.RICH_TEXT &&
      i.contentName === ECustomPageContentNames.CUSTOM_PAGE_RICH_TEXT_MAIN,
  );
  return {
    slug,
    page,
    store,
    content: pageContent,
  };
};

export default connect(mapState)(AdminEditCustomPage);
