
export enum EPageType {
  HOME = 1,
  PRODUCT = 2,
  CATEGORY = 3,
  CUSTOM_PAGE = 4,
  MEMBERSHIPS_LANDING = 5,
}

export enum EHomePageContentNames {
  WELCOME_CONTENT_SLIDER = 'welcomeContentSlider',
  SOCIAL_LINKS = 'socialLinks',
  WELCOME_CONTENT = 'welcomeContent',
  LINK_CONTENT_FOOTER = 'linkContentFooter',
  ABOUT_CONTENT_FOOTER = 'aboutContentFooter',
}

export enum ECustomPageContentNames {
  CUSTOM_PAGE_RICH_TEXT_MAIN = 'customPageRichTextMain'
}

export enum EContentType {
  RICH_TEXT = 1,
  PLAIN_TEXT = 2,
  SOCIAL_LINKS = 3,
  SLIDER_IMAGES = 4,
}

export enum EDiscountType {
  PERCENT = 'PERCENT',
  DOLLARS = 'DOLLARS',
  SHIPPING = 'SHIPPING',
}

export enum EMembershipState {
  PENDING = 'pending',
  ACTIVE = 'active',
}