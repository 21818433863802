import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { get, set } from 'lodash';
import { Button, Input } from 'reactstrap';
import MyInput from './MyInput';
import { IAppState } from 'store';

const MyAuthCheck = (props) => {
  const { isLoggedIn } = props;
  if (isLoggedIn) {
    return props.children;
  }

  return (
    <div>
      You must be logged in to access this section.
    </div>
  )
}

const mapState = ({ app }: IAppState, { location = {} }) => {
  const { match = {}, pathname } = location;
  const { user } = app;
  return {
    isLoggedIn: user?.id > 0,
  };
};

export default connect(mapState, null)(MyAuthCheck);
