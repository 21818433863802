import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrapOverride.css';
import './bootstrapUtil.css';
import './defaultTheme.css';
import { withRouter } from 'react-router';
import './pages/Cart/stripe.css';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import Home from './pages/Home';
import Page1 from './pages/Page-1';
import Page2 from './pages/Page-2';
import Account from './pages/Account/account';
import routes from './routes';
import css from './App.module.css';
import Category from './pages/Category/category';
import Product from './pages/Product/product';
import StoreAdmin from './pages/StoreAdmin/StoreAdmin';
import { IAppState } from './store';
import NotFound from './pages/NotFound';
import AdminCategories from './pages/AdminCategories/AdminCategories';
import AdminProducts from './pages/AdminProducts/AdminProducts';
import Cart from './pages/Cart/Cart';
import Login from './pages/Login/Login';
import Orders from './pages/Orders/Orders';
import AdminOrders from './pages/AdminOrders/AdminOrders';
import AdminEditPage from './pages/AdminEditPage/AdminEditPage';
import StoreFooter from './components/StoreHeader/StoreFooter';
import AdminAssets from './pages/AdminAssets/AdminAssets';
import CustomPage from './pages/CustomPage/CustomPage';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import AdminPromotions from './pages/AdminPromotions/AdminPromotions';
import AdminMailinglist from './pages/AdminMailinglist/AdminMailinglist';
import AdminMemberships from './pages/Admin/Memberships';
import Analytics from './components/Analytics/Analytics';
import Memberships from './pages/Memberships';
import BuyMembership from './pages/Memberships/Buy';
import * as api from './api';

const AdminOnlyComp = (props) => {
  if (
    props.store &&
    props.storeAdmin[props.store.id] &&
    props.storeAdmin[props.store.id].level === 1
  ) {
    return props.children;
  }
  return <NotFound />;
};
const mapState = ({ app }: IAppState) => {
  const { user, store = {}, storeAdmin = {} } = app;
  return {
    store,
    storeAdmin,
  };
};
const AdminOnly = connect(mapState, null)(AdminOnlyComp);

class ScrollToTopC extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <div />;
  }
}

const ScrollToTop = withRouter(ScrollToTopC);

const HelloApi = () => {
  useEffect(async () => {
    await api.helloApi();
  }, []);
  return <div />;
};

const content = (
  <React.Fragment>
    <div className={css.wrapper}>
      <ReactNotification />
      <ScrollToTop />
      <Analytics />
      <Switch>
        <Route exact path={routes.home} component={Home} />
        <Route exact path={routes.myAccount} component={Account} />
        <Route exact path={routes.login} component={Login} />

        <Route exact path={'/category/:name/:shortId'} component={Category} />
        <Route exact path={'/category/:shortId'} component={Category} />
        <Route exact path={'/product/:shortId/:name?'} component={Product} />
        <Route exact path={'/cart'} component={Cart} />
        <Route exact path={'/orders'} component={Orders} />
        <Route exact path={'/memberships'} component={Memberships} />
        <Route exact path={'/membership/:shortId'} component={BuyMembership} />
        <Route exact path={'/pages/:slug'} component={CustomPage} />
        <Route exact path={'/resetpassword'} component={ResetPassword} />

        <AdminOnly>
          <Route exact path={'/admin'} component={StoreAdmin} />
          <Route exact path={'/admin/editPage'} component={AdminEditPage} />
          <Route exact path={'/admin/categories'} component={AdminCategories} />
          <Route exact path={'/admin/products'} component={AdminProducts} />
          <Route exact path={'/admin/products/:shortId'} component={AdminProducts} />
          <Route exact path={'/admin/orders'} component={AdminOrders} />
          <Route exact path={'/admin/assets'} component={AdminAssets} />
          <Route exact path={'/admin/promotions'} component={AdminPromotions} />
          <Route exact path={'/admin/mailinglist'} component={AdminMailinglist} />
          <Route exact path={'/admin/memberships'} component={AdminMemberships} />
        </AdminOnly>
        <Route component={NotFound} />
      </Switch>
    </div>
    <StoreFooter />
  </React.Fragment>
);

const App: React.FC<any> = () => {
  if (typeof window !== 'undefined') {
    return <Suspense fallback={<div>Loading</div>}>{content}</Suspense>;
  }
  return content;
};

export default App;
