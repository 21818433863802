import React, { useCallback, useState, useRef } from 'react';
import { Input } from 'reactstrap';
import css from './CheckComponent.module.css';

interface Props {
  checked: boolean;
  label: string;
  value: string;
  onChange: Function;
}

export const CheckComponent: React.FC<Props> = ({ checked, label, onChange, checkChange }) => {
  const checkbox = useRef();
  return (
    <div>
      <Input
        innerRef={checkbox}
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          if (checkChange) {
            checkChange(e.target.checked);
          }
        }}
      />{' '}
      <span
        className={css.label}
        onClick={() => {
          checkbox.current && checkbox.current.click();
        }}
      >
        {label}
      </span>
    </div>
  );
};

export default CheckComponent;
