import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from 'react-router-dom';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import { truncate } from 'lodash';
import { IProductDto } from '../../../../../server/src/dto/IProduct';
import css from './ProductPreview.module.css';
import { connect } from 'react-redux';
import { IAppState } from 'store';

interface Props {
  product: IProductDto;
}

const ProductPreview: React.FC<Props> = ({ product, freeShipping, userHasMembership }) => {
  const img = product.images[0];
  return (
    <Link
      style={{ textDecoration: 'none', color: '#000' }}
      to={`/product/${product.slug || product.shortId}`}
    >
      <div className={css.wrapper}>
        <div>{truncate(product.name, { length: 25 })}</div>
        {product.brand && (
          <div>
            <small>{product.brand}</small>
          </div>
        )}
        <div
          style={{
            height: '120px',
            width: '100%',
          }}
        >
          {img && <ProductThumbnail ext={img.ext} shortId={img.shortId} size="130" />}
        </div>
        <small>
          {product.membersOnly && userHasMembership ? (
            <span>
              ${product.price} {freeShipping ? '+ ships free' : '+ shipping'}
            </span>
          ) : product.membersOnly ? (
            <span>Members Only</span>
          ) : (
            <span>
              {product.priceInCart ? 'Price In Cart' : `$${product.price}`}{' '}
              {freeShipping ? '+ ships free' : '+ shipping'}
            </span>
          )}
        </small>
        {product.avgRating && (
          <div>
            <div style={{ float: 'left', zoom: 0.8 }}>
              <StarRatingComponent starCount={5} value={product.avgRating} />
            </div>
            <div className={css.ratingCount} style={{ float: 'left' }}>
              &nbsp;({product.avgRating})
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

const mapState = ({ app }: IAppState, ownProps) => {
  const {
    myMemberships: [],
    memberships: [],
  } = app || {};
  let userHasMembership = false;
  if (ownProps && ownProps.product && ownProps.product.membersOnly) {
    Object.keys(ownProps.product.membershipMap).forEach((shortId) => {
      const enabled = Boolean(ownProps.product.membershipMap[shortId]);
      if (!enabled) {
        return;
      }
      const membership = app.memberships.find((m) => m.shortId === shortId);
      if (membership) {
        const found = app.myMemberships.find((i) => i.membershipId === membership.id);
        if (found) {
          userHasMembership = true;
        }
      }
    });
  }
  const freeShipping = Boolean(app?.store?.freeShipping);
  return {
    freeShipping,
    userHasMembership,
  };
};

export default connect(mapState, null)(ProductPreview);
