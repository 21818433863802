import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { meanBy, orderBy, set, keyBy, startCase, groupBy, range, last } from 'lodash';
import { setWith } from 'lodash/fp';
import { withRouter } from 'react-router';
import { store as notiStore } from 'react-notifications-component';
import StarRatingComponent from 'react-star-rating-component';
import { Table, Row, Col, Container, Button, Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { EditorState, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { IAppState } from '../../store';
import { IProductDto } from '../../../../../server/src/dto/IProduct';
import {
  IProductAddonFormItemStr,
  IProductAddonFormItemObj,
} from '../../../../../server/src/entity/store/webTypes';
import {
  loadSingleProductApi,
  addToCartApi,
  fetchCartApi,
  reviewProduct,
  loadProductsForCategoryApi,
} from '../../api';
import { setApp } from '../../store/app/actions';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import css from './product.module.css';
import ProductThumbnail, { getImgUrl } from 'components/ProductThumbnail/ProductThumbnail';
import MyInput from 'components/MyInput';
import { isClient, NONE_CONST, strStartCase } from 'utils';
import DraftToHtml from 'components/Modal/draftToHtml';
import ProductOptions from './ProductOptions';
import { Helmet } from 'react-helmet-async';
import { config } from 'config';
import Modal from 'components/Modal/Modal';
import { StoreProduct } from '../../../../../server/src/entity/store/storeProduct';
import { ProductReview } from '../../../../../server/src/entity/store/productReview';
import { Membership } from '../../../../../server/src/entity/memberships/membership';
import { IStoreDto } from '../../../../../server/src/dto/IStore';
import { Link } from 'react-router-dom';
import { Specifications } from './Specifications';
import { StoreCategory } from '../../../../../server/src/entity/store/storeCategory';

interface IProps {
  product: IProductDto;
  compareToProducts: IProductDto[];
  shortId: string;
  isLoggedIn: boolean;
  store: IStoreDto;
  isMemberOfProduct: boolean;
  categories: Partial<StoreCategory>[];
}
let Editor;
if (isClient()) {
  import('react-draft-wysiwyg').then((e) => {
    Editor = e.Editor;
  });
}

const getJsonAttributes = (attributes = {}) => {
  const jsonAttributes = Object.keys(attributes).reduce((acc, val) => {
    acc[val] = JSON.parse(attributes[val]);
    return acc;
  }, {});
  return jsonAttributes;
};

const ProductPage: React.FC<IProps> = ({
  store,
  isLoggedIn,
  isAdmin,
  product = {} as IProductDto,
  shortId,
  isMemberOfProduct,
  compareToProducts,
  categories = [],
}) => {
  const dispatch = useDispatch();
  const [showAddReview, setShowAddReview] = useState(false);
  const [review, setReview] = useState({
    comment: '',
    rating: 0,
  });
  const [productOptions, setProductOptions] = useState({
    quantity: 1,
    attributes: {},
  });
  const [isLoaded, setLoaded] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedAddon, setSelectedAddon] = useState({});
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [avgRating, setAvgRating] = useState(0);

  const fetch = async () => {
    loadSingleProductApi({
      shortId,
    }).then(async ({ product }: { product: IProductDto }) => {
      product.reviews = product.reviews.filter((r) => r.deletedAt == null) || [];
      const reviews = product.reviews;
      if (reviews && reviews.length) {
        const avg = meanBy(reviews, (p) => p.rating);
        setAvgRating(avg);
      }

      dispatch(
        setApp({
          loadedProducts: {
            [shortId]: product,
          },
        }),
      );
      setSelectedImg(product.images[0]);
      setEditorState(EditorState.createWithContent(convertFromRaw(product.description)));
      setLoaded(true);
      const productCat = product.categories[0];
      const cat = categories.find((c) => c.id === productCat);
      if (cat && cat.shortId) {
        const { results } = await loadProductsForCategoryApi({
          shortId: cat.shortId,
        });
        dispatch(
          setApp({
            categoryProducts: {
              [cat.shortId]: results,
            },
          }),
        );
      }
    });
  };

  useEffect(() => {
    fetch();
  }, [shortId]);

  const [adding, setAdding] = useState(false);
  const addToCart = useCallback(async () => {
    if (product?.requiredAttributes?.length) {
      // validate required options
      for (const attKey of product.requiredAttributes) {
        const hasAttr = product.attributes.find((i) => i.key === attKey);
        if (!hasAttr) {
          continue;
        }
        if (productOptions.attributes[attKey] === NONE_CONST && !!product.attributes[attKey]) {
          alert(`You must select an option for ${strStartCase(attKey)}`);
          return;
        }
      }
    }

    if (product.requiredAddons && Object.keys(product.requiredAddons)?.length) {
      for (const addonTitle of Object.keys(product.requiredAddons)) {
        const hasAddonItem = product.addonItems.find((i) => i.title === addonTitle);
        if (!hasAddonItem) {
          continue;
        }

        if (!selectedAddon[addonTitle] || selectedAddon[addonTitle] === NONE_CONST) {
          alert(`You must select an addon option for ${strStartCase(addonTitle)}`);
          return;
        }
      }
    }

    const jsonAttributes = getJsonAttributes(productOptions.attributes);

    const addons: IProductAddonFormItemObj[] = [];
    Object.keys(selectedAddon).forEach((addon) => {
      const addonObj: IProductAddonFormItemStr = selectedAddon[addon];
      const attributes = getJsonAttributes(addonObj?.options?.attributes);
      addons.push({
        productId: Number(addonObj.productId),
        attributes,
        quantity: productOptions.quantity,
      });
    });
    const selectedAttrZeroQty = Object.values(jsonAttributes).find((a) => a.qty === '0');
    if (selectedAttrZeroQty) {
      alert(`You have selected an option that is currently sold out.`);
      return;
    }

    setAdding(true);
    await addToCartApi({
      productId: product.id,
      attributes: jsonAttributes,
      quantity: productOptions.quantity,
    });

    for (const addon of addons) {
      await addToCartApi({
        productId: addon.productId,
        attributes: addon.attributes,
        quantity: addon.quantity,
      });
    }

    const { items } = await fetchCartApi();
    dispatch(
      setApp({
        cartItems: items,
      }),
    );
    notiStore.addNotification({
      title: 'Done!',
      message: 'Your item was added to the cart',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
    setTimeout(() => {
      setAdding(false);
      setProductOptions(setWith(productOptions, 'attributes', {}));
      setSelectedAddon({});
    }, 800);
  }, [product, productOptions, selectedAddon]);
  const keyedProductAddons = keyBy(product?.addonProducts, (p) => p.id);
  const groupedAddons = groupBy(product?.addonItems, (p) => p.title);

  let price = product.price || 0;
  for (const po of Object.keys(productOptions.attributes)) {
    const key = po;
    try {
      const valueObj = JSON.parse(productOptions.attributes[key]);
      if (valueObj.price) {
        price += Number(valueObj.price);
      }
    } catch (err) {}
  }

  Object.keys(selectedAddon).forEach((addon) => {
    const addonObj: IProductAddonFormItemStr = selectedAddon[addon];
    if (addonObj?.productId) {
      const product = keyedProductAddons[addonObj.productId];
      if (product) {
        price += product.price;
      }
    }
    Object.keys(addonObj?.options?.attributes || {}).forEach((attrKey) => {
      if (
        addonObj &&
        addonObj.options &&
        addonObj.options.attributes &&
        addonObj?.options?.attributes[attrKey] &&
        addonObj?.options?.attributes[attrKey] !== NONE_CONST
      ) {
        const valueObj = JSON.parse(addonObj?.options?.attributes[attrKey] || {});

        if (valueObj?.price) {
          price += Number(valueObj.price);
        }
      }
    });
  });

  price = Number(price.toFixed(2));
  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:title" content={product?.brand + ' | ' + product?.name} />
        {product?.images && (
          <meta property="og:image" content={getImgUrl(config.cdnPrefix, product?.images[0])} />
        )}
      </Helmet>
      <StoreHeader />
      {showAddReview && (
        <Modal onOutsideClick={() => setShowAddReview(false)}>
          <div>
            <h4>Add Review</h4>
            <Row>
              <Col>
                <MyInput type="textarea" state={review} setState={setReview} path="comment" />
              </Col>
            </Row>

            <Row>
              <Col xs={3}>
                Rating:{' '}
                <MyInput type="select" state={review} setState={setReview} path="rating">
                  <option value={''}>Select Rating</option>
                  {range(1, 6)
                    .reverse()
                    .map((i) => (
                      <option key={i} value={i}>
                        {i} Star
                      </option>
                    ))}
                </MyInput>
                <Button
                  onClick={async () => {
                    if (!review.comment || !review.rating) {
                      alert('Review or rating missing');
                      return;
                    }
                    await reviewProduct({
                      shortId: product.shortId,
                      comment: review.comment,
                      rating: Number(review.rating),
                    });
                    setReview({
                      comment: '',
                      rating: 0,
                    });
                    await fetch();
                    setShowAddReview(false);
                  }}
                  className="spacer"
                >
                  Submit Review
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      )}
      <Container>
        {isAdmin && (
          <div className="spacer">
            <b>Admin:</b>&nbsp;<Link to={`/admin/products/${product.shortId}`}>Edit Product</Link>
            <hr />
          </div>
        )}
        <h1>
          {product.brand} | {product.name}
        </h1>
        <hr />
        <h5>Description</h5>
        <Row>
          <Col xs={12} sm={6}>
            {product && <DraftToHtml content={product.description} />}
            <hr />
            <ProductOptions
              product={product}
              productOptions={productOptions}
              setProductOptions={setProductOptions}
            />
            <hr />
            {Object.keys(groupedAddons).length > 0 && (
              <div>
                <h5>Addon Items</h5>
                {Object.keys(groupedAddons).map((key) => {
                  const addons = groupedAddons[key];
                  return (
                    <Row key={key} className="spacer">
                      <Col xs={6}>
                        {strStartCase(key)}
                        {product.requiredAddons[key] ? '*' : ''}
                      </Col>
                      <Col xs={6}>
                        <MyInput
                          type="select"
                          state={selectedAddon}
                          setState={setSelectedAddon}
                          path={`${key}.productId`}
                        >
                          <option value={NONE_CONST}>Select an option</option>
                          {addons.map((i) => (
                            <option key={i.productId} value={i.productId}>
                              {keyedProductAddons[i.productId]?.name} ($
                              {keyedProductAddons[i.productId]?.price})
                            </option>
                          ))}
                        </MyInput>
                      </Col>
                      <Col xs={12}>
                        {keyedProductAddons[selectedAddon[key]?.productId]?.images.map((i) => {
                          return (
                            <div className={css.addonImg}>
                              <ProductThumbnail
                                allowEnlarge
                                ext={i.ext}
                                shortId={i.shortId}
                                size="50"
                              />
                            </div>
                          );
                        })}
                      </Col>
                      {selectedAddon[key]?.productId &&
                        keyedProductAddons[selectedAddon[key]?.productId] && (
                          <Col xs={12}>
                            <ProductOptions
                              product={keyedProductAddons[selectedAddon[key]?.productId]}
                              productOptions={selectedAddon[key].options || {}}
                              setProductOptions={(v) => {
                                // td
                                const obj = { ...selectedAddon };
                                set(obj, `${key}.options`, v);
                                setSelectedAddon(obj);
                              }}
                            />
                          </Col>
                        )}
                    </Row>
                  );
                })}
                <hr />
              </div>
            )}
            {product.membersOnly && isMemberOfProduct ? (
              <div>Members Price: ${price} </div>
            ) : product.membersOnly ? (
              <div>Price: Members Only</div>
            ) : (
              <div>
                Price:{' '}
                {product.priceInCart === true ? <span>See In Cart</span> : <span>${price}</span>}
              </div>
            )}
            <div>
              Shipping: {Boolean(store.freeShipping) ? 'Free' : `$${store.flatShippingPrice}`}
            </div>

            <hr />
            <div className="spacer">
              {(!product.membersOnly || isMemberOfProduct) && (
                <Row>
                  <Col xs={6} sm={4}>
                    <Input
                      type="select"
                      value={productOptions.quantity}
                      onChange={(e) => {
                        setProductOptions(setWith(productOptions, 'quantity', e.target.value));
                      }}
                    >
                      {range(1, 10).map((i) => {
                        return <option value={i}>{i}</option>;
                      })}
                    </Input>
                  </Col>
                  <Col xs={6} sm={8}>
                    {product.quantity < 1 && !product.quantityUnlimited ? (
                      <span>Sold out</span>
                    ) : (
                      <Button disabled={adding} onClick={addToCart}>
                        {adding ? 'Adding' : 'Add to Cart'}
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="xs-spacer"></div>
            <Row>
              <Col xs={2}>
                {orderBy(product?.images || [], (i) => i.sortOrder).map((img) => {
                  return (
                    <div
                      key={img.id}
                      className={css.thumbs}
                      onMouseEnter={() => {
                        setSelectedImg(img);
                      }}
                      onClick={() => {
                        setSelectedImg(img);
                      }}
                    >
                      <ProductThumbnail ext={img.ext} shortId={img.shortId} />
                    </div>
                  );
                })}
              </Col>
              <Col
                style={{
                  padding: '0',
                  margin: '0',
                }}
              >
                <div className={css.bigPicture}>
                  {selectedImg && (
                    <ProductThumbnail
                      allowEnlarge
                      ext={selectedImg.ext}
                      shortId={selectedImg.shortId}
                      full
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Specifications product={product} compareTo={compareToProducts} />
        <hr />
        <Row className="spacer">
          <Col>
            <h5 className="spacer">Reviews</h5>
            <Row>
              <Col xs={10}>
                {Boolean(product?.reviews?.length) ? (
                  <div>
                    Reviews: {product?.reviews?.length}
                    <br />
                    <StarRatingComponent starCount={5} value={avgRating} editing={false} />
                  </div>
                ) : (
                  <div>Be the first to review this product!</div>
                )}
              </Col>
              <Col xs={2}>
                {isLoggedIn ? (
                  <Button
                    onClick={() => {
                      setShowAddReview(true);
                    }}
                    style={{ zoom: 0.7 }}
                  >
                    Add Review
                  </Button>
                ) : (
                  'Login to Review'
                )}
              </Col>
            </Row>
            {(product.reviews || []).map((r: ProductReview) => {
              return (
                <Row key={r.id}>
                  <Col>
                    <small>{moment(r.createdAt).format('MMM, YYYY')}</small>
                    <p>{r.comment}</p>
                    <hr />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row style={{ height: '200px' }}></Row>
      </Container>
    </React.Fragment>
  );
};
const mapState = ({ app }: IAppState, { location = {} }) => {
  const { match = {}, pathname } = location;
  const shortId = last(pathname.split('/'));
  const {
    store,
    user,
    categories,
    categoryProducts = {},
    loadedProducts = {},
    memberships = [],
    myMemberships = {},
    storeAdmin = {},
    products = [],
  } = app;
  const isLoggedIn = user && user.id;
  const isAdmin = storeAdmin[store.id] && storeAdmin[store.id].userId === user?.id;
  const product = loadedProducts[shortId];
  let isMemberOfProduct = false;

  if (isLoggedIn && product && product.membersOnly) {
    const myMembershipsMap = keyBy(myMemberships, (m) => m.membershipId);
    const membershipsShortIdLookup = keyBy(memberships, (m) => m.shortId);
    Object.keys(product.membershipMap).forEach((membershipShortId) => {
      const enabled = Boolean(product.membershipMap[membershipShortId]);
      if (!enabled) {
        return;
      }
      const membershipItem = membershipsShortIdLookup[membershipShortId];
      if (membershipItem && myMembershipsMap[membershipItem.id]) {
        isMemberOfProduct = true;
      }
    });
  }
  let compareToProducts = [];
  if (product) {
    const thisProductCat = product.categories[0];
    const cat = categories.find((i) => i.id === thisProductCat);
    if (
      cat &&
      categoryProducts &&
      categoryProducts[cat.shortId] &&
      categoryProducts[cat.shortId].filter
    ) {
      compareToProducts = categoryProducts[cat?.shortId]?.filter((i) => i.id !== product.id);
    }
  }
  return {
    isMemberOfProduct,
    product,
    compareToProducts,
    shortId,
    categories,
    isLoggedIn,
    store,
    isAdmin,
    categories,
  };
};

export default withRouter(connect(mapState, null)(ProductPage));
