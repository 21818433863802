import React from 'react';
import { get, set } from 'lodash';
import { Input, Form } from 'reactstrap';

interface Props {
  filterValue?: (e: Event) => string;
  cancelOnChange?: (e: Event) => boolean;
  renderer?: (prop: any) => string;
  setState: (state: object) => void;
  type: string;
  state: object;
  path: string;
  innerRef?: any;
}

const MyInput: React.FC<Props> = ({
  filterValue,
  cancelOnChange,
  type,
  state,
  setState,
  path,
  renderer,
  ...props
}) => {
  console.log('myinput type', type);
  const value = get(state, path, '');
  if (type === 'textarea') {
    return (
      <textarea
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          const obj = set({ ...state }, path, value);
          setState(obj);
        }}
      ></textarea>
    );
  }
  return (
    <Input
      value={renderer ? renderer(value) : value}
      type={type}
      onChange={(e) => {
        if (cancelOnChange && cancelOnChange(e) === true) {
          return;
        }
        let value;
        if (filterValue) {
          value = filterValue(e);
        } else if (type === 'checkbox') {
          value = e.target.checked;
        } else {
          value = e.target.value;
        }
        const obj = set({ ...state }, path, value);
        setState(obj);
      }}
      {...props}
    />
  );
};

export default MyInput;
