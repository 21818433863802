import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from './rootReducer';
import { IUserDto } from '../../../../server/src/dto/IUser';
import { UserAddress } from '../../../../server/src/entity/userAddress';
import { ICategoryDto } from '../../../../server/src/dto/ICategory';
import { IStoreDto } from '../../../../server/src/dto/IStore';
import { StoreAdmin } from '../../../../server/src/entity/store/storeAdmins';
import { IProductDto } from '../../../../server/src/dto/IProduct';
import { Store } from '../../../../server/src/entity/store/store';
import { Cart } from '../../../../server/src/entity/store/cart';
import { StoreStripeInfo } from '../../../../server/src/entity/store/storeStripeInfo';
import { StripeCreditCard } from '../../../../server/src/entity/stripeCreditCard';
import { StorePurchase } from '../../../../server/src/entity/store/storePurchase';
import { StorePage } from '../../../../server/src/entity/store/storePage';
import { StoreContent } from '../../../../server/src/entity/store/content';
import { Image } from '../../../../server/src/entity/store/image';
import { Membership } from '../../../../server/src/entity/memberships/membership';
import { MembershipUser } from '../../../../server/src/entity/memberships/membershipUser';

export interface IApp {
  myMemberships: MembershipUser[];
  memberships: Membership[];
  admin: IAdmin;
  user: IUserDto;
  storeStripe: {
    stripeClientId: string;
  };
  addresses: UserAddress[];
  categories: ICategoryDto[];
  store: IStoreDto;
  categoryProducts: {
    [shortId: string]: any[];
  };
  featured: IProductDto[];
  loadedProducts: {
    [shortId: string]: IProductDto;
  };
  storeAdmin: {
    [storeId: number]: StoreAdmin;
  };
  cartItems: Cart[];
  products: IProductDto[];
  cards: StripeCreditCard[];

  config: { [key: string]: string };
  orders: StorePurchase[];
  storePages: StorePage[];
  contentForPage: { [pageId: number]: StoreContent[] };
  imgLookup: { [shortId: string]: Image };
}

export interface IAdmin {
  store: Store;
  storeStripeData: StoreStripeInfo[];
  admins: StoreAdmin[];
}

export interface IAppState {
  app: IApp;

  [key: string]: any;
}

export type StoreParams = {
  initialState?: IAppState;
  middleware?: any[];
};

export const configureStore = ({ initialState, middleware = [] }: StoreParams) => {
  const devtools =
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devtools || compose;

  const store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...[thunk].concat(...middleware))),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () =>
        store.replaceReducer(require('./rootReducer').default),
      );
    }
  }

  return store;
};

export default configureStore;
