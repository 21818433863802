import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { Card, Container, Row, Col, Table, Button } from 'reactstrap';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import * as api from 'api';
import { MailingList } from '../../../../../server/src/entity/store/mailingList';
import { IMembershipAdminRecordDto } from '../../../../../../server/src/dto/IUser';
import MyInput from 'components/MyInput';
import { renderDate } from '../../../utils';
import MyEditableRow from 'components/MyEditableRow';

interface ICreateNewState {
  name?: string;
  monthlyPrice?: string;
  annualPrice?: string;
}

const fetch = async ({ setList, setLoading, setUserList }) => {
  setLoading(true);
  const { memberships } = await api.adminGetMemberships();
  setList(memberships);
  if (setUserList) {
    const resp = await api.adminGetUserMemberships();
    setUserList(resp.members);
  }
  setLoading(false);
};

const AdminMemberships = (props) => {
  const [editingState, setEditingState] = useState({});
  const [list, setList] = useState<IMembershipAdminRecordDto[]>([]);
  const [userList, setUserList] = useState([]);
  const [newMembershipState, setNewMembershipState] = useState<ICreateNewState>({});
  const [isLoading, setLoading] = useState(false);

  const saveMembership = async (state) => {
    setLoading(true);
    await api.adminCreateMembership(state);
    setNewMembershipState({});
    await fetch({ setList, setLoading });
    setLoading(false);
  };

  useEffect(() => {
    fetch({ setList, setLoading, setUserList });
  }, []);

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col xs={10}>
            <h1>Admin: Memberships</h1>
            <Table striped bordered hover size="sm" responsive>
              <thead>
                <td>Name</td>
                <td>Annual Cost</td>
                <td>Description</td>
                <td>members</td>
                <td>Deleted</td>
                <td>Action</td>
              </thead>
              {list.map((membership, ix) => {
                return (
                  <MyEditableRow
                    isEditing={editingState[ix] === true}
                    showDelete
                    setEditing={(cancel) => {
                      setEditingState({
                        ...editingState,
                        [ix]: !cancel,
                      })
                    }}
                    saveRow={async () => {
                      // save ix
                      const data = list[ix];
                      await api.adminUpdateMembership(data);
                      setEditingState({
                        ...editingState,
                        [ix]: false,
                      });
                    }}
                    columnPrefix={{
                      'monthlyPrice': '$',
                      'annualPrice': '$',
                    }}
                    editableProps={['name', 'monthlyPrice', 'annualPrice', 'description']}
                    columnTypes={{
                      'description': 'textarea',
                    }}
                    columns={['name', 'annualPrice', 'description', 'users.length', 'deletedAt']}
                    state={membership}
                    setState={(data) => {
                      const newArr = [...list];
                      newArr[ix] = data;
                      setList(newArr);
                    }}
                    unDelete={async () => {
                      const deleteRow = list[ix];
                      deleteRow.restoredAt = 'now';
                      await api.adminUpdateMembership(deleteRow);
                      fetch({ setList, setLoading });
                    }}
                    setDeleted={async () => {
                      const deleteRow = list[ix];
                      deleteRow.deletedAt = 'now'
                      await api.adminUpdateMembership(deleteRow);
                      fetch({ setList, setLoading });
                    }}
                    showActions
                  />
                  // <tr>
                  //   <td>{membership.name}</td>
                  //   <td>${membership.monthlyPrice}</td>
                  //   <td>${membership.annualPrice}</td>
                  //   <td>{membership.shortId}</td>
                  //   <td>{membership?.users?.length}</td>
                  // </tr>
                );
              })}
            </Table>
            <hr />
            <Container>
              <h3>Create new Membership</h3>
              <Row>
                <Col xs={12} sm={3}>
                  Name:
                  <MyInput
                    type="text"
                    state={newMembershipState}
                    setState={setNewMembershipState}
                    path="name"
                  />
                </Col>

                <Col xs={12} sm={3}>
                  Annual Price:
                  <MyInput
                    type="text"
                    state={newMembershipState}
                    setState={setNewMembershipState}
                    path="annualPrice"
                  />
                </Col>

                {/* <Col xs={12} sm={3}>
                  Monthly Price:
                  <MyInput
                    type="text"
                    state={newMembershipState}
                    setState={setNewMembershipState}
                    path="monthlyPrice"
                  />
                </Col> */}
                <Col xs={12} sm={3}>
                  Description:
                  <MyInput
                    type="textarea"
                    state={newMembershipState}
                    setState={setNewMembershipState}
                    path="description"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button
                    disabled={isLoading}
                    onClick={() => saveMembership(newMembershipState)}
                  >Save</Button>
                </Col>
              </Row>
            </Container>
            <hr />
            <Container>
              <h3>Current Members</h3>
              <Table>
                <thead>
                  <td>User</td>
                  <td>Subscription</td>
                  <td>Created</td>
                  <td>Expires</td>
                </thead>
                {userList.map((u) => {
                  return (
                    <tr>
                      <td>{get(u, 'user.name')}
                        <br/>
                        {get(u, 'user.email')}
                      </td>
                      <td>{get(u, 'membership.name')}</td>
                      <td>{renderDate(u?.startDate, 'yyyy-mm-dd')}</td>
                      <td>{renderDate(u?.endDate, 'yyyy-mm-dd')}</td>
                    </tr>
                  )
                })}
              </Table>
            </Container>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AdminMemberships;
