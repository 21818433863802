import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Input } from 'reactstrap';
import { keyBy } from 'lodash';
import { IAppState } from '../../store';
import { sliderItems } from '../Slider/data';
import Slider from '../Slider/Slider';
import DraftToHtml from 'components/Modal/draftToHtml';
import { EHomePageContentNames, EPageType } from '../../../../../server/src/entity/store/enums';
import { getImgUrl } from 'components/ProductThumbnail/ProductThumbnail';

interface IProps {
  title: string;
  sliderImages: any[];
}
const StoreWelcome = ({ title, sliderImages, welcomeText }: IProps) => {
  return (
    <Container>
      <Row>
        {sliderImages && sliderImages.length > 0 && (
          <Col>
            <Slider images={sliderImages} />
          </Col>
        )}
      </Row>
      <div className="spacer" />
      <Row>
        <Col>
          <DraftToHtml content={welcomeText} />
        </Col>
      </Row>
    </Container>
  );
};
const mapState = (state: IAppState) => {
  const { app } = state;
  const { config, store, categories = [], contentForPage = {}, imgLookup = {} } = state.app;

  const homePage = app.storePages?.find((a) => a.pageType === EPageType.HOME);
  const contentLookup = keyBy(
    app.contentForPage && app.contentForPage[homePage?.id],
    (c) => c.contentName,
  );
  const sliderImgs: string[] =
    contentLookup[EHomePageContentNames.WELCOME_CONTENT_SLIDER]?.richContent;
  return {
    title: store?.homePageTitle,
    sliderImages: sliderImgs?.map((a) => ({
      src: getImgUrl(config.cdnPrefix, imgLookup[a], true),
    })),
  };
};
const mapDispatch = () => {
  return {};
};
export default connect(mapState, mapDispatch)(StoreWelcome);
