import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { range } from 'lodash';
import css from './StoreHeader.module.css';
import MyInput from 'components/MyInput';
import { connect } from 'react-redux';
import { IAppState } from 'store';
import * as api from 'api';
import { config } from 'config';
import { Link } from 'react-router-dom';

const Searchbar = ({}) => {
  const [state, setState] = useState({
    term: '',
  });

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (state.term.length < 1) {
      return;
    }
    const f = async () => {
      setLoading(true);

      const response = await api.searchProductsApi({
        term: state.term,
      });

      setResults(response.results);
      setLoading(false);
    };
    f();
  }, [state.term]);
  return (
    <div>
      {state.term && (
        <div className={css.searchResults}>
          <div className={css.searchCategory}>
            <div className={css.categoryTitle}>Products</div>
          </div>
          {results.map((r) => {
            return (
              <div className={css.resultItem} key={r.id}>
                <div>
                  <Link
                    to={`/product/${r.slug || r.shortId}`}
                    onClick={() => {
                      setState({ term: '' });
                    }}
                  >
                    <div className={css.resultImg}>
                      <img
                        src={`${config.cdnPrefix}/images/${r.images[0].shortId}/thumb.${r.images[0].ext}`}
                      />
                    </div>
                    <div className={css.resultText}>
                      <div className={css.resultItemName}>
                        {r.brand} {r.name}
                      </div>
                      <div className={css.resultPrice}>${r.price}</div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
          <div className={css.searchNotFound}>Can't find what you're looking for? Contact Us!</div>
        </div>
      )}
      <MyInput
        className={css.search}
        state={state}
        setState={setState}
        path="term"
        placeholder="Product search"
        autocomplete="off"
      />
    </div>
  );
};

const mapState = ({ app }: IAppState) => {
  return {};
};

export default connect(mapState)(Searchbar);
