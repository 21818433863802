import moment from 'moment';
import React, { useState, useEffect } from 'react';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import { Container, Row, Col, Table } from 'reactstrap';
import SyncLoader from 'react-spinners/SyncLoader';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import DropUpload from 'pages/AdminProducts/DropUpload';
import * as api from 'api';
import { EEntityType } from '../../../../../server/src/entity/store/generics';
import { Link } from 'react-router-dom';
import { Image } from '../../../../../server/src/entity/store/image';
import { config } from 'config';

const IMAGES = ['png', 'jpg', 'jpeg', 'gif'];

const fetch = async ({ setAssets }) => {
  const { results } = await api.adminGetAssetsApi();
  setAssets(results);
};

const upload = async ({ files }) => {
  const formData = new FormData();
  formData.append('entityType', EEntityType.ASSET.toString());
  for (let i = 0; i < files.length; i++) {
    const value = files[i];
    formData.append('image', value);
  }
  await api.uploadImagesApi({ formData });
};

const AdminAssets: React.FC = ({}) => {
  const [resetFilesCount, setResetFilesCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [uploadState, setUploadState] = useState({});
  const onDropFiles = async (files) => {
    setLoading(true);
    await upload({ files });
    await fetch({ setAssets });
    setResetFilesCount((s) => s + 1);
    setLoading(false);
  };

  useEffect(() => {
    fetch({ setAssets });
  }, []);
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col>
            <h4>Upload</h4>
            <p>Upload Files and Images</p>
            <Row>
              {/* <Col>
                <div className="spacer">
                  <label>Short Name</label>
                  <MyInput path="name" state={uploadState} setState={setUploadState} />
                </div>

                <Button onClick={upload} className="spacer">
                  Upload
                </Button>
              </Col> */}
              <Col>
                {loading && (
                  <div>
                    Loading... <br />
                    <SyncLoader css={{ margin: '10px' }} size={10} color={'#36D7B7'} loading={loading} />
                  </div>
                )}
                {!loading && (
                  <div className="spacer">
                    <DropUpload resetFilesCount={resetFilesCount} onDrop={onDropFiles} />
                  </div>
                )}
              </Col>
            </Row>

            <hr />
            <h4 className="spacer">Files</h4>
            <Table>
              <thead>
                <th>Name</th>
                <th style={{ width: '100px' }}>Preview</th>
                <th>Link</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {assets.map((i: Image) => {
                  const thumbUrl = `${config.cdnPrefix}/images/${i.shortId}/thumb.${i.ext}`;
                  const origUrl = `${config.cdnPrefix}/images/${i.shortId}/original.${i.ext}`;
                  return (
                    <tr key={i.id}>
                      <td>
                        {i.name}
                        <br />
                        {moment(i.createdAt).format('YYYY/MM/DD h:mm:ssa')}
                      </td>
                      <td>
                        {IMAGES.includes(i.ext) && (
                          <img
                            style={{
                              width: '100%',
                              height: 'auto',
                            }}
                            src={thumbUrl}
                          />
                        )}
                      </td>
                      <td>
                        {IMAGES.includes(i.ext) && (
                          <a href={thumbUrl} target="_blank">
                            Thumbnail
                          </a>
                        )}
                        <br />
                        <a href={origUrl} target="_blank">
                          Original
                        </a>
                      </td>
                      <td>
                        <Link
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await api.adminDeleteAssetsApi({
                              ids: [i.id],
                            });
                            await fetch({ setAssets });
                          }}
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AdminAssets;
