import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Container, Row, Col } from 'reactstrap';
import Form from '@rjsf/core';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { setApp } from 'store/app/actions';
import {
  updateMyAccountApi,
  setAddressApi,
  deleteAddressApi,
  bootstrapApi,
  updatePasswordApi,
} from '../../api';
import { IAppState } from '../../store';
import { IUserDto } from '../../../../../server/src/dto/IUser';
import css from './account.module.css';
import { UserAddress } from '../../../../../server/src/entity/userAddress';
import MyInput from 'components/MyInput';
import { notify } from 'utils';

export const schema = {
  title: 'Account Profile',
  type: 'object',
  required: ['name', 'email'],
  properties: {
    name: { type: 'string', title: 'Name' },
    email: { type: 'string', title: 'Email' },
    phone: { type: 'string', title: 'Phone #' },
  },
};

export const addressSchema = {
  title: 'Address',
  type: 'object',
  required: [],
  properties: {
    name: { type: 'string', title: 'Name' },
    add1: { type: 'string', title: 'Street Address' },
    add2: { type: 'string', title: 'Address 2' },
    city: { type: 'string', title: 'City' },
    state: { type: 'string', title: 'State' },
    zip: { type: 'string', title: 'Zip' },
    phone: { type: 'string', title: 'Phone' },
  },
};

const Account: React.FC<any> = ({
  user = {},
  addresses = [],
}: {
  user: IUserDto;
  addresses: UserAddress[];
}) => {
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState({});
  const [showAddress, setShowAddress] = useState(false);
  const [editAddressId, setEditAddressId] = useState(null);

  const data = {
    name: user.name,
    email: user.email,
    phone: user.phone,
  };

  const updatePassword = async () => {
    const { password1, password2 } = newPassword;
    if (password1 !== password2) {
      alert('Passwords do not match');
      return;
    }

    if (password1.length < 5) {
      alert('Passwords must be 6 characters long or more');
      return;
    }

    const uppercase = password1.match(/[A-Z]/g);
    const lowercase = password1.match(/[a-z]/g);
    const number = password1.match(/[0-9]/g);

    if (!uppercase || uppercase.length < 1) {
      alert('Passwords must have at least 1 UPPER case character');
      return;
    }

    if (!lowercase || lowercase.length < 1) {
      alert('Passwords have at least 1 lower case character');
      return;
    }

    if (!number || number.length < 1) {
      alert('Passwords have at least 1 numeric value 0-9');
      return;
    }

    await updatePasswordApi({
      password: password1,
    });
    setNewPassword({
      password1: '',
      password2: '',
    });
    notify({
      message: 'Success',
    });
  };

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            {typeof window !== 'undefined' && (
              <Form
                schema={schema}
                formData={data}
                onChange={console.log('changed')}
                onSubmit={async ({ formData }) => {
                  await updateMyAccountApi(formData);
                  const data = await bootstrapApi();
                  dispatch(setApp(data));
                }}
                onError={console.log('errors')}
              >
                <Button type="submit">Save</Button>
              </Form>
            )}
            <br />

            <hr />
            <h5>Password</h5>
            <div>
              <label>New Password</label>
              <MyInput
                type="password"
                path="password1"
                state={newPassword}
                setState={setNewPassword}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <MyInput
                type="password"
                path="password2"
                state={newPassword}
                setState={setNewPassword}
              />
            </div>
            <div className="spacer">
              <Button onClick={updatePassword}>Update Password</Button>
            </div>

            <hr />
            <h6>Addresses on file</h6>
            {addresses.map((i) => {
              return (
                <Card key={i.id}>
                  <CardBody>
                    <CardText>
                      <div className={css.address}>
                        <div>{i.name}</div>
                        <div>{i.add1}</div>
                        {i.add2 && <div>{i.add2}</div>}
                        <div>
                          {i.city}, {i.state} {i.zip}
                        </div>
                        {i.phone && <div>{i.phone}</div>}
                      </div>
                      <Button
                        type="button"
                        onClick={() => {
                          setEditAddressId(i.id);
                        }}
                      >
                        Edit
                      </Button>{' '}
                      <Button
                        type="button"
                        onClick={async () => {
                          setShowAddress(false);
                          setEditAddressId(null);
                          await deleteAddressApi(i.id);
                          const data = await bootstrapApi();
                          dispatch(setApp(data));
                        }}
                      >
                        Delete
                      </Button>
                    </CardText>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
          <Col>
            <Button
              onClick={() => {
                setShowAddress(true);
              }}
            >
              Add Address
            </Button>
            {typeof window !== 'undefined' && showAddress && (
              <Form
                schema={addressSchema}
                formData={{}}
                onSubmit={async ({ formData }) => {
                  await setAddressApi(formData);
                  const data = await bootstrapApi();
                  dispatch(setApp(data));
                  setShowAddress(false);
                  setEditAddressId(null);
                }}
              >
                <Button type="submit">Save</Button>{' '}
                <Button
                  type="button"
                  onClick={() => {
                    setShowAddress(false);
                  }}
                >
                  Cancel
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapState = (state: IAppState) => {
  const { app } = state;
  const { user, addresses } = app;
  return {
    user,
    addresses,
  };
};
export default connect(mapState, null)(Account);
