import React from 'react';
import { get, set } from 'lodash';
import { Button, Input } from 'reactstrap';
import MyInput from './MyInput';

interface Props {
  state: any;
  columns: Array<string>;
  editableProps: Array<string>;
  setState: (d: any) => void;
  setEditing: (ix: any) => void;
  isEditing: boolean;
  ix: number;
  showActions: boolean;
  saveRow: () => void;
  columnPrefix: {[key: string]: string};
  columnTypes: {[key: string]: string};
  showDelete: boolean;
  setDeleted: () => void;
  unDelete: () => void;
}

const MyEditableRow: React.FC<Props> = ({ 
  columns,
  editableProps,
  columnTypes,
  state,
  setState,
  setEditing,
  setDeleted,
  unDelete,
  isEditing,
  showActions,
  saveRow,
  ix,
  columnPrefix,
  showDelete,
}) => {
 if (isEditing) {

  return (
    <tr>
      {columns.map(c => {
        if (editableProps.includes(c)) {
        return (
          <td>
            <MyInput
              type={get(columnTypes, c, 'text')}
              state={{...state}}
              setState={setState}
              path={c}
            />
          </td>
        );
        } else {
          return (
            <td>{get(state, c)}</td>
          )
        }
      })}
      <td>
         <Button size="sm"
          onClick={() => {
            saveRow();
          }}
         >Save</Button>&nbsp;
         <Button size="sm"
          onClick={() => {
            setEditing(true);
          }}
         >Cancel</Button>
        </td>
    </tr>
  )

 } else {
   return (
     <tr>
       {columns.map((c, ix) => {
         let str = get(state, c, '') || '';
         if (str && str.replace) {
          str = str.replace(/(?:\r\n|\r|\n)/g, '<br>')
         }
         return (
           <td>
             {columnPrefix[c] ? columnPrefix[c] : ''}
             <span
              dangerouslySetInnerHTML={{ __html: str }}
            />
           </td>
         )
       })}
       <td>
       {showDelete && unDelete && !!state.deletedAt && <span>
         <Button size="sm"
          onClick={() => {
            unDelete();
          }}
         >Restore</Button>&nbsp;</span>}
         {showDelete && !state.deletedAt && <span>
         <Button size="sm"
          onClick={() => {
            setDeleted();
          }}
         >Delete</Button>&nbsp;</span>}
         <Button size="sm"
          onClick={() => {
            setEditing(ix);
          }}
         >Edit</Button>
        </td>
     </tr>
   );
 }
};

export default MyEditableRow;