import React, { useEffect, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import css from './ProductThumbnail.module.css';
import { Image as TImage } from '../../../../../server/src/entity/store/image';
import Modal from 'components/Modal/Modal';
import { fetchImage } from 'api';
import MyLoader from 'components/MyLoader/MyLoader';

export enum EThumbnailSize {
  ORIGINAL = 1,
  THUMB = 2,
}

type Props = ExternalProps & InjectedProps;
interface CompProps {
  shortId: string;
  ext: string;
  size?: string;
  full?: boolean;
  allowEnlarge?: boolean;
}

type ExternalProps = React.FC<CompProps>;

export const getImgUrl = (cdnPrefix: string, img: TImage, full = false) => {
  return `${cdnPrefix}/images/${img?.shortId}/${full ? 'original' : 'thumb'}.${img?.ext}`;
};

interface InjectedProps {
  config: { [key: string]: string };
}

const ProductThumbnail: React.FC<Props> = ({
  allowEnlarge = false,
  full = false,
  config,
  shortId,
  ext,
  size = '50',
}) => {
  const [showModal, setShowModal] = useState(false);
  const src = `${config.cdnPrefix}/images/${shortId}/${full ? 'original' : 'thumb'}.${ext}`;
  const lgSrc = `${config.cdnPrefix}/images/${shortId}/original.${ext}`;
  const [imgData, setImgData] = useState(null);
  useEffect(() => {
    setImgData(null);
    const newImg = new Image();
    newImg.src = src;
    newImg.onload = () => {
      console.log('onload src ', src);
      setImgData(newImg);
    };
  }, [shortId]);
  return (
    <React.Fragment>
      {showModal && (
        <Modal
          onOutsideClick={() => {
            setShowModal(false);
          }}
        >
          <img
            onClick={() => {
              setShowModal(false);
            }}
            src={lgSrc}
            style={{
              width: '100%',
              height: '100%',
              display: !imgData ? 'hidden' : undefined,
            }}
          />
        </Modal>
      )}
      {imgData ? (
        <img
          onClick={() => {
            if (allowEnlarge) {
              setShowModal(true);
            }
          }}
          style={{
            width: '100%',
            height: '100%',
          }}
          className={css.img}
          src={imgData.src}
        />
      ) : (
        <MyLoader />
      )}
    </React.Fragment>
  );
};

const mapState = (state) => {
  const config = state.app.config;
  return {
    config,
  };
};

export default connect(mapState, null)(ProductThumbnail) as ExternalProps;
