import React, { useEffect } from 'react';
import { startCase, range, last, keyBy } from 'lodash';
import StoreHeader from '../../components/StoreHeader/StoreHeader';
import { connect, useDispatch } from 'react-redux';
import { IAppState } from '../../store';
import { withRouter } from 'react-router';
import { ICategoryDto } from '../../../../../server/src/dto/ICategory';
import { Row, Col, Container } from 'reactstrap';
import ProductPreview from '../../components/ProductPreview/ProductPreview';
import { ProductAttributeMap, IProductDto } from '../../../../../server/src/dto/IProduct';
import { loadProductsForCategoryApi } from '../../api';
import { setApp } from '../../store/app/actions';
import { strStartCase } from 'utils';

interface IProps {
  categories: ICategoryDto[];
  products: IProductDto[];
  location: any;
  shortId: string;
}

const Category: React.FC<IProps> = ({
  products,
  selectedCategory = {},
  categories = [],
  ...rest
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    loadProductsForCategoryApi({
      shortId: rest.shortId,
    }).then(({ results }) => {
      dispatch(
        setApp({
          categoryProducts: {
            [rest.shortId]: results,
          },
        }),
      );
    });
  }, [rest.shortId]);
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h1>Category: {selectedCategory.name}</h1>
        <Row>
          {/* <Col className="xs-hidden" sm={4} md={3}>
            Filter
            {Object.values(ProductAttributeMap)
              .sort()
              .map((i) => {
                return <div key={i}>{strStartCase(i)}</div>;
              })}
            <hr />
          </Col> */}
          <Col>
            <Row>
              {!products?.length && (
                <div>No products found for this category. Check back later.</div>
              )}
              {(products || []).map((p) => {
                return (
                  <Col key={p.id} lg={3} md={4} sm={4} xs={6}>
                    <ProductPreview product={p} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapState = ({ app }: IAppState, { location = {} }) => {
  const { match = {}, pathname } = location;
  const shortId = last(pathname.split('/'));
  const { categories, categoryProducts = {} } = app;
  const lookup = keyBy(categories, (c) => c.shortId);
  return {
    products: categoryProducts[shortId],
    shortId,
    categories,
    selectedCategory: lookup[shortId],
  };
};

export default withRouter(connect(mapState, null)(Category));
