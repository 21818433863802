if (process.env.NODE_ENV === 'production') {
  require('dotenv').config({ path: process.cwd()+'/.env.production' });
} else {
  require('dotenv').config();
}

export const config = {
  cdnPrefix: process.env.CDN_PREFIX,
  apiUrl: process.env.API_URL,
  env: process.env.NODE_ENV,
  fullStack: Boolean(process.env.FULL_STACK),
};

console.log('config', config);
