import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { IAppState } from 'store';
import { getMyOrdersApi } from 'api';
import { StoreProduct } from '../../../../../server/src/entity/store/StoreProduct';
import { StorePurchase } from '../../../../../server/src/entity/store/StorePurchase';
import { Link } from 'react-router-dom';
import { strStartCase } from 'utils';
import css from './Orders.module.css';
import { config } from 'config';

const fetch = async ({ setState }) => {
  const { orders } = await getMyOrdersApi();
  setState({
    orders,
  });
};

interface IState {
  orders: StorePurchase[];
}

const Orders = () => {
  const [state, setState]: [IState] = useState({
    orders: [],
    productLookup: {},
  });
  useEffect(() => {
    fetch({ setState });
  }, []);
  const { orders } = state;
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h1>My Orders</h1>
        <Row>
          <Col>Here are your previous orders</Col>
        </Row>

        {orders.map((o) => {
          let status = '';
          if (o.authedAt) {
            status = 'Processing Order';
          }
          if (o.capturedAt) {
            status = 'Packing Order';
          }
          if (o.shippedAt) {
            status = 'Shipped';
          }
          if (o.cancelledAt) {
            status = 'Cancelled';
          }
          return (
            <Row key={o.id} className={[css.orderItem]}>
              <Col>
                <div className={[]}>
                  <Row className={[css.borderBottom, css.smallText]}>
                    <Col>{moment(o.createdAt).format('MM/DD/YYYY h:mma')}</Col>
                    <Col>Total: ${o.total}</Col>
                    <Col>id: {o.shortId}</Col>
                  </Row>
                </div>
                <Row key={o.id} className={[css.borderBottom]}>
                  <Col>
                    {o.orderItems?.map((product) => {
                      return (
                        <Row className={[css.borderBottomNotLast]}>
                          <Col xs={2}>
                            <img
                              style={{ width: '80%' }}
                              src={`${config.cdnPrefix}/images/${product.product.images[0].shortId}/thumb.${product.product.images[0].ext}`}
                            />
                          </Col>
                          <Col>
                            <Link to={`/product/${product.slug || product.shortId}`}>
                              {strStartCase(product.name)}
                            </Link>{' '}
                            {Object.values(product?.attributes || {}).map((attribute) => {
                              return (
                                <div style={{ marginLeft: '10px' }} key={attribute.key}>
                                  {strStartCase(attribute.key)}: {strStartCase(attribute.value)}
                                </div>
                              );
                            })}
                            {product?.files?.length > 0 && <div>Digital Downloads:</div>}
                            {product?.files?.map(file => {
                              return (
                                <div>
                                  <a href={file.signedUrl} target="_blank">
                                    {file.name}
                                  </a>
                                </div>
                              );
                            })}
                          </Col>
                          <Col xs={2}>
                            ${Number(product.price).toFixed(2)}
                            <br />
                            Qty: {product.quantity}
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
                <Row className={css.smallText}>
                  <Col>
                    <div>Status: {status}</div>
                    {status === 'Shipped' && (
                      <div style={{ display: 'block' }}>
                        {o?.shippingRecords[0] && (
                          <div>
                            {o?.shippingRecords[0].carrier}: {o?.shippingRecords[0].trackingId}
                          </div>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState) => {
  return {
    config: app.config,
  };
};

export default connect(mapState, null)(Orders);
