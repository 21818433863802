import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';

interface Props {
  onDrop: (files: any[]) => void;
  maxSize?: number;
  resetFilesCount?: number;
}

export const DropUpload: React.FC<Props> = (props) => {
  const [files, setFiles] = useState([]);
  const { maxSize } = props;
  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [...files, ...acceptedFiles];
      if (maxSize && newFiles.length > maxSize) {
        alert('Error: One file at a time');
        return;
      }
      setFiles(newFiles);
      props.onDrop(newFiles);
    },
    [files],
  );

  useEffect(() => {
    setFiles([]);
  }, [props.resetFilesCount]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          style={{
            width: '100%',
            height: '100px',
            border: '1px dashed #000',
          }}
        >
          Drag Files Here ... or Click to Upload
        </div>
        <div>
          {files.map((file) => {
            return <div>{file.name}</div>;
          })}
        </div>
      </div>
      {files.length > 0 && (
        <div>
          <Button
            onClick={() => {
              setFiles([]);
              props.onDrop([]);
            }}
            className="small"
          >
            Clear files
          </Button>
        </div>
      )}
    </div>
  );
};

export default DropUpload;
