import React, { useState, useCallback, useEffect, useRef } from 'react';
import css from './MyHover.module.css';
import { getNewId } from 'utils';
import { useForceUpdate } from 'hooks/forceUpdate';

const hoverArr = {};
let zindex = 1000;

const MyHover = ({ children, HoverComponent, disabled }) => {
  const [hover, setHover] = useState(false);
  const localTimeout = useRef(0);
  localTimeout.current = -1;
  const fu = useForceUpdate();
  return (
    <div
      style={{
        display: 'inline',
      }}
      onMouseEnter={() => {
        fu();
        console.log('onMouseEnter ', )
        clearTimeout(localTimeout.current);
        if (!disabled) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        const timeout = setTimeout(() => {
          setHover(false);
        }, 1000);
        localTimeout.current = timeout;
      }}
    >
      {hover && <div style={{ zIndex: ++zindex }} className={css.myHoverWrapper}>{HoverComponent}</div>}
      {children}
    </div>
  );
};

export default MyHover;
