import React, { useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import { keyBy } from 'lodash';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import { Archive } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReactLogo } from '../../assets/react.svg';
import css from '../../App.module.css';
import { Locale } from '../../store/app/types';
import { setLocale } from '../../store/app/actions';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import StoreWelcome from 'components/StoreWelcome/StoreWelcome';
import { IAppState } from 'store';
import ProductPreview from 'components/ProductPreview/ProductPreview';
import { EPageType, EHomePageContentNames } from '../../../../../server/src/entity/store/enums';
import { Link } from 'react-router-dom';

const Memberships: React.FC<any> = (props) => {
  console.log('membership props', props);
  const { memberships, myMemberships } = props;
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h4>Available Memberships</h4>
        <hr />
        <Row>
          {memberships.map((m) => {
            const hasMembership = myMemberships.find((a) => a.membershipId === m.id);
            return (
              <Col xs={6} lg={3}>
                <Card>
                  <CardBody>
                    <div>{m.name}</div>
                    <hr />
                    <div
                      dangerouslySetInnerHTML={{ __html: m?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                    />
                    <br />
                    <br />
                    <Row>
                      <Col>
                      Price: 
                      </Col>
                      <Col>
                      ${m.annualPrice}/year
                      
                      </Col>
                    </Row>
                      <br />
                      {hasMembership == null &&
                      <Link to={`/membership/${m.shortId}`}>
                        <Button size="sm">Buy Yearly Membership</Button>
                      </Link>
                      }
                      {hasMembership != null && <Button disabled size="sm">Subscribed</Button>}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app = {} }: IAppState) => {
  const memberships = app?.memberships || [];
  const myMemberships = app?.myMemberships || [];
  return {
    myMemberships,
    memberships,
    welcomeText: '',
  };
};

export default connect(mapState, null)(Memberships);
