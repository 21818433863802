
export enum EEntityType {
  PRODUCT = 1,
  CATEGORY = 2,
  PAGE = 3,
  COMMENT = 4,
  ASSET = 5,
}

export enum EFileType {
  DIGITAL_PRODUCT = 1,
}