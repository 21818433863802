import React, { useCallback, useState, useEffect } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, connect } from 'react-redux';
import { last, keyBy } from 'lodash';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import { Archive } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReactLogo } from '../../assets/react.svg';
import css from '../../App.module.css';
import { Locale } from '../../store/app/types';
import { setLocale, setApp } from '../../store/app/actions';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import StoreWelcome from 'components/StoreWelcome/StoreWelcome';
import { IAppState } from 'store';
import ProductPreview from 'components/ProductPreview/ProductPreview';
import { EPageType, EHomePageContentNames } from '../../../../../server/src/entity/store/enums';
import MyAuthCheck from 'components/MyAuthCheck';
import { isClient } from 'utils';
import '../Cart/stripe.css';
import MyLoader from 'components/MyLoader/MyLoader';
import Modal from 'components/Modal/Modal';
import * as api from 'api';
import { useHistory } from 'react-router';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const StripeCheckout = ({ onSuccess, isLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      setError(result.error.message);
      console.log(result.error.message);
    } else if (result.token) {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      const token = result.token;
      onSuccess(token);
    }
  };
  return (
    <div>
      {error && (
        <Modal
          onOutsideClick={() => {
            setError(null);
          }}
        >
          <h1>Error</h1>
          {error}
          <p>Please try again</p>
        </Modal>
      )}
      <form onSubmit={handleSubmit}>
        <label>Credit Card Details</label>
        <p>You can pay via credit card below</p>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <br />
        <Button className="btn-success" disabled={!stripe || isLoading}>
          Confirm order
        </Button>
        {isLoading && <MyLoader />}
      </form>
    </div>
  );
};

const BuyMembership: React.FC<any> = (props) => {
  console.log('BuyMembership props', props);
  const { membership, store } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (isClient()) {
      if (store.stripePublicKey) {
        import('@stripe/stripe-js').then((s) => {
          setStripePromise(s.loadStripe(store.stripePublicKey));
        });
      }
    }
  }, [store]);

  const submitPaymentViaCard = async (stripeToken) => {
    setLoading(true);
    try {
      await api.subscribeToMembershipApi({
        stripeToken,
        membershipShortId: membership.shortId,
      });
    } catch (err) {
      console.error(err);
    }
    const data = await api.bootstrapApi();
    dispatch(setApp(data));
    history.push('/account');
    setLoading(false);
  };

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h4>Sign up for {membership.name}</h4>
        <hr />
        <Row>
          <Col xs={12} lg={3}>
            <Card>
              <CardBody>
                <div>{membership.name}</div>
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: membership?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                  }}
                />
                <br />
                <br />
                <Row>
                  <Col>Price:</Col>
                  <Col>${membership.annualPrice}/year</Col>
                </Row>
                <br />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} sm={12}>
            <Row>
              <Col>
                <b>Purchase a 1 year membership</b>
                <div>
                  <MyAuthCheck>
                    {stripePromise && (
                      <div className="spacer stripeContainer" style={{ maxWidth: '400px' }}>
                        <Elements stripe={stripePromise}>
                          <StripeCheckout
                            isLoading={isLoading}
                            onSuccess={(token) => {
                              submitPaymentViaCard(token);
                            }}
                          />
                        </Elements>
                      </div>
                    )}
                  </MyAuthCheck>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app = {} }: IAppState, { location = {} }) => {
  const { match = {}, pathname } = location;
  const shortId = last(pathname.split('/'));
  const { myMemberships } = app;
  const memberships = app?.memberships || [];
  const membership = memberships.find((i) => i.shortId === shortId) || {};
  console.log('mapstate memberships', memberships);
  return {
    myMemberships,
    membership,
    store: app.store,
  };
};

export default connect(mapState, null)(BuyMembership);
