import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Col, Row, Container, Button, Table } from 'reactstrap';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import MyInput from 'components/MyInput';
import { renderDate } from 'utils';
import { EDiscountType } from '../../../../../server/src/entity/store/enums';
import * as api from 'api';
import MyLoader from 'components/MyLoader/MyLoader';

const fetch = async ({ setDiscounts }) => {
  const { results } = await api.adminGetDiscountsApi();
  setDiscounts(results);
};

const AdminPromotions = () => {
  const [state, setState] = useState({});
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch({ setDiscounts });
  }, []);

  const add = async () => {
    const { discountType, code, discountAmount, validUntil } = state;
    setLoading(true);
    try {
      await api.adminCreateDiscountCodeApi({
        discountType,
        code,
        discountAmount,
        validUntil,
      });
    } catch (err) {}
    await fetch({ setDiscounts });
    setLoading(false);
  };
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col xs={10}>
            <h1>Admin: Promotions</h1>
            <Row>
              <Col xs={5}>
                <h4>New Promotion:</h4>
                <div className="spacer">
                  <label>Code</label>
                  <MyInput path="code" state={state} setState={setState} />
                </div>

                <div className="spacer">
                  <label>Discount Type</label>
                  <MyInput type="select" path="discountType" state={state} setState={setState}>
                    <option value={''}>Choose discount type</option>
                    <option value={EDiscountType.PERCENT}>Percent Off</option>
                    <option value={EDiscountType.DOLLARS}>Dollars Off</option>
                    {/* <option value={EDiscountType.SHIPPING}>Shipping Discount</option> */}
                  </MyInput>
                </div>

                <div className="spacer">
                  <label>Discount Amount</label>
                  <MyInput
                    path="discountAmount"
                    state={state}
                    setState={setState}
                    filterValue={(v) => v.target.value?.match(/\d+/g)?.join('')}
                  />
                </div>

                <div className="spacer">
                  <label>Valid Until</label>
                  <br />
                  <small>ex: 2020-12-04 10:00 pm</small>
                  <MyInput
                    path="validUntil"
                    state={state}
                    setState={setState}
                    placeholder="ex: 2020-12-04 10:00 pm"
                  />
                  {renderDate(state.validUntil)}
                </div>

                <div className="spacer">
                  <Button disabled={loading} onClick={add}>
                    Add Promo Code
                  </Button>
                  {loading && <MyLoader />}
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="spacer">
              <Col>
                <Table>
                  <thead>
                    <th>Code</th>
                    <th>Expires</th>
                    <th>Discount</th>
                    <th>Used</th>
                    <th>Delete</th>
                  </thead>
                  <tbody>
                    {discounts.map((d) => {
                      return (
                        <tr key={d.id}>
                          <td>{d.code}</td>
                          <td>{moment(d.validUntil).format('YYYY-MM-DD hh:mma')}</td>
                          <td>
                            {d.discountType === EDiscountType.DOLLARS && '$'}
                            {d.discountType === EDiscountType.SHIPPING && '$'}
                            {d.discountAmount}
                            {d.discountType === EDiscountType.PERCENT && '%'}
                            <br />
                            {d.discountType === EDiscountType.SHIPPING
                              ? 'off Shipping'
                              : 'off Purchase'}
                            <br />
                          </td>
                          <td>0</td>
                          <td>
                            {d.deletedAt && 'Deleted'}
                            {!d.deletedAt && (
                              <Button
                                onClick={async () => {
                                  await api.adminDeleteDiscountCodeApi({ ids: [d.id] });
                                  await fetch({ setDiscounts });
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = () => {
  return {};
};

export default connect(mapState, null)(AdminPromotions);
