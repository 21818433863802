import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { keyBy } from 'lodash';
import { Button, Row, Col } from 'reactstrap';
import { IAppState } from 'store';
import { isClient, notify, sleep, strStartCase } from 'utils';
import * as api from '../../api';
import { EPageType, EHomePageContentNames } from '../../../../../server/src/entity/store/enums';
import { setApp } from 'store/app/actions';
import DropUpload from 'pages/AdminProducts/DropUpload';
import { EEntityType } from '../../../../../server/src/entity/store/generics';
import MyInput from 'components/MyInput';
import { Image } from '../../../../../server/src/entity/store/image';
import { StoreContent } from '../../../../../server/src/entity/store/content';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import { Link } from 'react-router-dom';

interface Props {
  imgLookup: { [shortId: string]: Image };
  homePage: any;
  contentLookup: { [type: string]: StoreContent[] };
}

let Editor;
if (isClient()) {
  import('react-draft-wysiwyg').then((e) => {
    Editor = e.Editor;
  });
}

const AdminEditHomePage: React.FC<Props> = ({ imgLookup, contentLookup, homePage }) => {
  const dispatch = useDispatch();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [update, shouldUpdate] = useState(0);
  const editor = useRef();
  const [socialLinks, setSocialLinks] = useState({});
  const [welcomeText, setWelcomeText] = useState(EditorState.createEmpty());
  const [aboutusText, setAboutusText] = useState(EditorState.createEmpty());
  const [linksText, setLinksText] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [sliderImgs, setSliderImgs] = useState([]);

  useEffect(() => {
    if (contentLookup[EHomePageContentNames.WELCOME_CONTENT_SLIDER]) {
      setSliderImgs(contentLookup[EHomePageContentNames.WELCOME_CONTENT_SLIDER]?.richContent);
    }
    if (contentLookup[EHomePageContentNames.SOCIAL_LINKS]) {
      setSocialLinks(contentLookup[EHomePageContentNames.SOCIAL_LINKS]?.richContent);
    }

    if (contentLookup[EHomePageContentNames.WELCOME_CONTENT]) {
      setWelcomeText(
        EditorState.createWithContent(
          convertFromRaw(contentLookup[EHomePageContentNames.WELCOME_CONTENT]?.richContent),
        ),
      );
    }
    if (contentLookup[EHomePageContentNames.ABOUT_CONTENT_FOOTER]) {
      setAboutusText(
        EditorState.createWithContent(
          convertFromRaw(contentLookup[EHomePageContentNames.ABOUT_CONTENT_FOOTER]?.richContent),
        ),
      );
    }

    if (contentLookup[EHomePageContentNames.LINK_CONTENT_FOOTER]) {
      setLinksText(
        EditorState.createWithContent(
          convertFromRaw(contentLookup[EHomePageContentNames.LINK_CONTENT_FOOTER]?.richContent),
        ),
      );
    }
  }, [update, contentLookup]);

  useEffect(() => {
    setTimeout(() => {
      shouldUpdate(update + 1);
    }, 350);
  }, []);

  const saveAll = useCallback(async () => {
    const welcomeContent = convertToRaw(welcomeText.getCurrentContent());
    const linkContentFooter = convertToRaw(linksText.getCurrentContent());
    const aboutContentFooter = convertToRaw(aboutusText.getCurrentContent());
    await api.adminSaveHomePageContentApi({
      socialLinks,
      welcomeContent,
      linkContentFooter,
      aboutContentFooter,
      sliderImgs,
    });
    const data = await api.bootstrapApi();
    dispatch(setApp(data));
    notify({
      message: 'Content saved',
    });
  }, [sliderImgs, welcomeText, linksText, aboutusText, socialLinks]);

  const saveImages = useCallback(async () => {
    if (filesToUpload.length) {
      const formData = new FormData();
      formData.append('entityId', homePage.id);
      formData.append('entityType', EEntityType.PAGE.toString());
      for (let i = 0; i < filesToUpload.length; i++) {
        const value = filesToUpload[i];
        formData.append('image', value);
      }
      setLoading(true);
      try {
        const { shortIds, newImgMeta } = await api.uploadImagesApi({ formData });
        const newImgLookup = keyBy(newImgMeta, (i) => i.shortId);
        dispatch(
          setApp({
            imgLookup: {
              ...imgLookup,
              ...newImgLookup,
            },
          }),
        );
        setSliderImgs([...sliderImgs, ...shortIds]);
      } catch (err) {
        console.error(err);
      }
      setFilesToUpload([]);
      setLoading(false);
    }
  }, [filesToUpload]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h4>Welcome Text</h4>
          <div className="border">
            {Editor && (
              <Editor ref={editor} editorState={welcomeText} onEditorStateChange={setWelcomeText} />
            )}
          </div>
          <hr />
          <h4>Slider Images</h4>
          <div>
            <Row>
              <Col xs={6}>
                <DropUpload
                  onDrop={(files) => {
                    setFilesToUpload([...filesToUpload, ...files]);
                  }}
                />
                <br />
                <Button disabled={loading} onClick={saveImages}>
                  Save Images
                </Button>
                {loading && <div>Saving...</div>}
              </Col>
              <Col>
                Images:
                {sliderImgs.map((i, ix, origArr) => {
                  return (
                    <Row key={i} className="spacer">
                      <Col>
                        <Link
                          onClick={(e) => {
                            const arr = [...origArr];
                            e.preventDefault();
                            if (ix < arr.length) {
                              const tmp = arr[ix];
                              arr[ix] = arr[ix + 1];
                              arr[ix + 1] = tmp;
                            }
                            setSliderImgs(arr);
                          }}
                        >
                          Move Down
                        </Link>
                        <br />
                        <Link
                          onClick={(e) => {
                            const arr = [...origArr];
                            e.preventDefault();
                            if (ix > 0) {
                              const tmp = arr[ix];
                              arr[ix] = arr[ix - 1];
                              arr[ix - 1] = tmp;
                            }
                            setSliderImgs(arr);
                          }}
                        >
                          Move Up
                        </Link>
                        <br />
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            const newArr = [];
                            origArr.forEach((a, ix2) => {
                              if (ix !== ix2) {
                                newArr.push(a);
                              }
                            });
                            setSliderImgs(newArr);
                          }}
                        >
                          Remove
                        </Link>
                        <br />
                      </Col>
                      <Col>
                        {imgLookup[i] && (
                          <ProductThumbnail ext={imgLookup[i].ext} shortId={i} size="80" />
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </div>

          <hr />
          <h4>Footer</h4>
          <h5>About Us</h5>
          <div className="border spacer">
            {Editor && (
              <Editor ref={editor} editorState={aboutusText} onEditorStateChange={setAboutusText} />
            )}
          </div>
          <hr />
          <h5>Links</h5>
          {['facebook', 'instagram', 'youtube', 'twitter'].map((s) => {
            return (
              <Row key={s} className="spacer">
                <Col xs={4}>{strStartCase(s)}</Col>
                <Col xs={4}>
                  <MyInput path={s} setState={setSocialLinks} state={socialLinks} type="text" />
                </Col>
              </Row>
            );
          })}

          <hr />
          <h5>Additional Links</h5>
          <div className="border">
            {Editor && (
              <Editor ref={editor} editorState={linksText} onEditorStateChange={setLinksText} />
            )}
          </div>
        </Col>
      </Row>
      <Row className="spacer">
        <Col>
          <Button disabled={loading} onClick={saveAll}>
            Save All
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState, props) => {
  const { storePages = [], contentForPage = {}, imgLookup = {} } = app;
  const homePage = storePages?.find((a) => a.pageType === EPageType.HOME);
  let contentLookup = {};
  if (homePage) {
    contentLookup = keyBy(contentForPage[homePage?.id], (c) => c.contentName);
  }
  return {
    contentLookup,
    homePage,
    imgLookup,
  };
};

export default connect(mapState, null)(AdminEditHomePage);
