import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Container, Row, Col, Input } from 'reactstrap';
import { setWith } from 'lodash/fp';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { setApp, setAdmin } from 'store/app/actions';
import { bootstrapApi, getStoreAdminApi, saveStoreAdminApi } from '../../api';
import { IAppState } from '../../store';
import { IUserDto, IUserAddressDto } from '../../../../../server/src/dto/IUser';
import css from './StoreAdmin.module.css';
import { Link } from 'react-router-dom';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import { StoreAdmin } from '../../../../../server/src/entity/store/storeAdmins';
import { Store } from '../../../../../server/src/entity/store/store';
import { StoreStripeInfo } from '../../../../../server/src/entity/store/storeStripeInfo';

const fetchData = async ({ dispatch }) => {
  const data = await getStoreAdminApi();
  dispatch(setAdmin(data));
};
interface IProps {
  admins: StoreAdmin[];
  store: Store;
  storeStripeData: StoreStripeInfo;
}

const StoreAdminComp: React.FC<IProps> = ({ admins, store, storeStripeData }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData({ dispatch });
  }, []);

  const [storeState, setStoreState] = useState({});
  useEffect(() => {
    setStoreState({
      store,
      admins,
      storeStripeData,
    });
  }, [store ? store.id : null]);
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col>
            <h1>Store Admin</h1>
            <Container>
              <div>
                Store Name
                <Input
                  type="text"
                  value={storeState?.store?.name}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'name'], e.target.value));
                  }}
                />
              </div>

              <div>
                Logo
                <Input
                  type="text"
                  value={storeState?.store?.logoUrl}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'logoUrl'], e.target.value));
                  }}
                />
              </div>

              <div>
                Default Store URL: https://{storeState?.store?.host} <br />
                Additional URLs:
                <Input
                  type="text"
                  value={storeState?.store?.hosts?.join(',')}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['store', 'hosts'], e.target.value.split(',')),
                    );
                  }}
                />
              </div>

              <div>
                Google Analytics ID
                <Input
                  type="text"
                  value={storeState?.store?.gaId}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'gaId'], e.target.value));
                  }}
                />
              </div>

              <div>
                Google Tag Mgr ID
                <Input
                  type="text"
                  value={storeState?.store?.gtmId}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'gtmId'], e.target.value));
                  }}
                />
              </div>

              <div>
                Support Email
                <Input
                  type="text"
                  value={storeState?.store?.email}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'email'], e.target.value));
                  }}
                />
              </div>

              <div>
                Support Phone #
                <Input
                  type="text"
                  value={storeState?.store?.phone}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'phone'], e.target.value));
                  }}
                />
              </div>

              <hr />
              <h4>Shipping settings</h4>

              <div>
                Flat Rate Shipping Price $
                <Input
                  type="text"
                  value={storeState?.store?.flatShippingPrice}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['store', 'flatShippingPrice'], e.target.value),
                    );
                  }}
                />
              </div>
              <br />
              <div>
                Storewide Free Shipping
                <br />
                <Input
                  type="checkbox"
                  checked={storeState?.store?.freeShipping === true ? 'checked' : false}
                  onChange={(e) => {
                    const checked = e.target.checked ? true : false;
                    setStoreState(setWith(storeState, ['store', 'freeShipping'], checked));
                  }}
                />
              </div>

              <hr />
              <h4>Paypal Payment settings</h4>
              <div>
                ClientID
                <Input
                  type="text"
                  value={storeState?.store?.paypalClientId}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'paypalClientId'], e.target.value));
                  }}
                />
              </div>
              <div>
                SecretId
                <Input
                  type="text"
                  value={storeState?.store?.paypalSecret}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'paypalSecret'], e.target.value));
                  }}
                />
              </div>


              <hr />
              <h4>Stripe Payment settings</h4>
              {/* <div>
                Amazon Checkout Id
                <Input
                  type="text"
                  value={storeState?.store?.amazonCheckoutId}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['store', 'amazonCheckoutId'], e.target.value),
                    );
                  }}
                />
              </div>

              <div>
                Amazon Checkout Secret
                <Input
                  type="text"
                  value={storeState?.store?.amazonCheckoutSecret}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['store', 'amazonCheckoutSecret'], e.target.value),
                    );
                  }}
                />
              </div> */}

              {/* <div>
                Store Paypal
                <Input
                  type="text"
                  value={storeState?.store?.paypalEmail}
                  onChange={(e) => {
                    setStoreState(setWith(storeState, ['store', 'paypalEmail'], e.target.value));
                  }}
                />
              </div> */}

              <div>
                Stripe Public ID
                <Input
                  type="text"
                  value={storeState?.storeStripeData?.stripeClientId}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['storeStripeData', 'stripeClientId'], e.target.value),
                    );
                  }}
                />
              </div>
              <div>
                Stripe Secret ID
                <Input
                  type="text"
                  value={storeState?.storeStripeData?.stripeSecret}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['storeStripeData', 'stripeSecret'], e.target.value),
                    );
                  }}
                />
              </div>

              <div>
                Payment Descriptor
                <Input
                  type="text"
                  value={storeState?.storeStripeData?.chargeDescriptor}
                  onChange={(e) => {
                    setStoreState(
                      setWith(storeState, ['storeStripeData', 'chargeDescriptor'], e.target.value),
                    );
                  }}
                />
              </div>
              <hr />
              <div>
                <Button
                  onClick={async () => {
                    await saveStoreAdminApi(storeState);
                    const data = await bootstrapApi();
                    dispatch(setApp(data));
                  }}
                >
                  Save
                </Button>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapState = (state: IAppState) => {
  const { app } = state;
  const { admins, store, storeStripeData } = app.admin || {};
  return {
    admins,
    store,
    storeStripeData,
  };
};
export default connect(mapState, null)(StoreAdminComp);
