import React, { useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import { keyBy } from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import draftToHtml from 'draftjs-to-html';
import { Archive } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReactLogo } from '../../assets/react.svg';
import css from '../../App.module.css';
import { Locale } from '../../store/app/types';
import { setLocale } from '../../store/app/actions';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import StoreWelcome from 'components/StoreWelcome/StoreWelcome';
import { IAppState } from 'store';
import ProductPreview from 'components/ProductPreview/ProductPreview';
import { EPageType, EHomePageContentNames } from '../../../../../server/src/entity/store/enums';

const App: React.FC<any> = ({ products, welcomeText }) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <StoreHeader />
      <StoreWelcome welcomeText={welcomeText} />

      <hr />
      <Container>
        <h4>Featured Items</h4>
        <Row>
          {(products || []).map((p) => {
            return (
              <Col
                key={p.id}
                lg={3}
                md={4}
                sm={4}
                xs={6}
                style={{ height: '100%', minHeight: '230px' }}
              >
                <ProductPreview product={p} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState) => {
  const products = app?.featured || [];
  const homePage = app.storePages?.find((a) => a.pageType === EPageType.HOME);
  const contentLookup = keyBy(
    app.contentForPage && app.contentForPage[homePage?.id],
    (c) => c.contentName,
  );
  const welcomeText = contentLookup
    ? contentLookup[EHomePageContentNames.WELCOME_CONTENT]?.richContent
    : '';
  return {
    products,
    welcomeText,
  };
};

export default connect(mapState, null)(App);
