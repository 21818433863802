import React from 'react';
import { last } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { IAppState } from 'store';
import DraftToHtml from 'components/Modal/draftToHtml';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { ECustomPageContentNames } from '../../../../../server/src/entity/store/enums';

const CustomPage = ({ page, content }) => {
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>{content && <DraftToHtml content={content} />}</Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState, ownProps) => {
  const { location } = ownProps;
  const { match, pathname } = location;
  const slug = last(pathname.split('/'));
  const { storePages, contentForPage } = app;

  const page = storePages.find((i) => i.slug === slug);
  const pageContent = contentForPage[page.id];
  const content = pageContent.find(
    (c) => c.contentName === ECustomPageContentNames.CUSTOM_PAGE_RICH_TEXT_MAIN,
  );
  return {
    page,
    content: content?.richContent,
  };
};

export default connect(mapState)(CustomPage);
