import React, { useState, useEffect } from 'react';
import css from './StoreFooter.module.css';
import { Row, Col, Button } from 'reactstrap';
import { keyBy } from 'lodash';
import MyInput from 'components/MyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { mailingListSignUpApi } from 'api';
import { notify, isClient } from 'utils';
import { connect } from 'react-redux';
import { IAppState } from 'store';
import { EHomePageContentNames, EPageType } from '../../../../../server/src/entity/store/enums';
import DraftToHtml from 'components/Modal/draftToHtml';
import MyLoader from 'components/MyLoader/MyLoader';

let Editor;

const StoreFooter = ({ aboutUs, socialLinks, links }) => {
  const [update, shouldUpdate] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      shouldUpdate(update + 1);
    }, 350);
  }, []);
  const [mailingList, setMailingList] = useState({});
  return (
    <div className={css.footer}>
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Row>
            <Col>
              <h6>About Us</h6>
              <DraftToHtml content={aboutUs} />
            </Col>
            <Col>
              <h6>Keep in touch</h6>
              {socialLinks?.facebook && (
                <a style={{ marginRight: '8px' }} target="_blank" href={socialLinks?.facebook}>
                  <FontAwesomeIcon
                    style={{ color: '#3b5998', width: '30px', height: '30px' }}
                    icon={faFacebook}
                  />
                </a>
              )}
              {socialLinks?.youtube && (
                <a style={{ marginRight: '8px' }} target="_blank" href={socialLinks?.youtube}>
                  <FontAwesomeIcon
                    style={{ color: '#c4302b', width: '30px', height: '30px' }}
                    icon={faYoutube}
                  />
                </a>
              )}

              {socialLinks?.instagram && (
                <a style={{ marginRight: '8px' }} target="_blank" href={socialLinks?.instagram}>
                  <FontAwesomeIcon
                    style={{ color: '#3f729b', width: '30px', height: '30px' }}
                    icon={faInstagram}
                  />
                </a>
              )}

              {socialLinks?.twitter && (
                <a style={{ marginRight: '8px' }} target="_blank" href={socialLinks?.twitter}>
                  <FontAwesomeIcon
                    style={{ color: '#1DA1F2', width: '30px', height: '30px' }}
                    icon={faTwitter}
                  />
                </a>
              )}
              <hr />
              <h6>Links</h6>
              <DraftToHtml content={links} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={4} className="text-left">
          <h6>Mailing List</h6>
          <div style={{ fontSize: '12px' }}>
            Sign up for our store mailing list for exclusive offers
          </div>
          <MyInput type="text" state={mailingList} setState={setMailingList} path="email" />
          <Button
            disabled={loading}
            className={css.signUpBtn}
            onClick={async () => {
              if (!mailingList.email) {
                return;
              }
              setLoading(true);
              await mailingListSignUpApi({
                email: mailingList.email,
              });
              setMailingList({
                email: '',
              });
              notify({
                message: "We've added you to the list",
              });
              setLoading(false);
            }}
          >
            Sign Up
          </Button>
          {loading && <MyLoader />}
        </Col>
      </Row>
    </div>
  );
};

const mapState = ({ app }: IAppState) => {
  const homePage = app.storePages?.find((a) => a.pageType === EPageType.HOME);
  let contentLookup = {};
  if (app.contentForPage && homePage) {
    contentLookup = keyBy(app.contentForPage[homePage?.id], (c) => c.contentName);
  }
  return {
    aboutUs: contentLookup[EHomePageContentNames.ABOUT_CONTENT_FOOTER]?.richContent,
    socialLinks: contentLookup[EHomePageContentNames.SOCIAL_LINKS]?.richContent,
    links: contentLookup[EHomePageContentNames.LINK_CONTENT_FOOTER]?.richContent,
  };
};

export default connect(mapState, null)(StoreFooter);
