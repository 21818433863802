import React, { useCallback, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import queryString from 'query-string';
import { Button, Card, CardBody, CardText, Container, Row, Col } from 'reactstrap';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import { IAppState } from 'store';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import css from './AdminEditPage.module.css';
import { withRouter } from 'react-router';
import AdminEditHomePage from './AdminEditHomePage';
import AdminEditCustomPage from './AdminEditCustomPage';
import MyInput from 'components/MyInput';
import * as api from '../../api';
import { setApp } from 'store/app/actions';

interface Props {
  page: string;
}

const AdminEditPage: React.FC<Props> = ({ page }) => {
  const dispatch = useDispatch();
  const [newPage, setNewPage] = useState({});

  const createNewPage = async () => {
    const { title, slug } = newPage;
    await api.adminCreatePageApi({
      title,
      slug,
    });
    const data = await api.bootstrapApi();
    dispatch(setApp(data));
  };

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col>
            <h1>Content Manager</h1>
            {page === 'home' && <AdminEditHomePage />}
            {page && page !== 'home' && <AdminEditCustomPage slug={page} />}
            {!page && (
              <div style={{ width: '300px' }}>
                Create new page
                <br />
                <label>Title</label>
                <MyInput
                  path="title"
                  setState={(obj) => {
                    setNewPage({
                      title: obj.title,
                      slug: obj.title.replace(/\s/g, '-').toLowerCase(),
                    });
                  }}
                  state={newPage}
                />
                <label>Url Slug</label>
                <MyInput path="slug" setState={setNewPage} state={newPage} />
                <br />
                <Button onClick={createNewPage}>Create new Page</Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const mapState = ({ app }: IAppState, props) => {
  const qs = queryString.parse(props.location.search);

  return {
    page: qs.page,
  };
};

export default withRouter(connect(mapState, null)(AdminEditPage));
