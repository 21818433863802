import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Container, Row, Col, Table, Button, Input } from 'reactstrap';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import StoreHeader from 'components/StoreHeader/StoreHeader';
import AdminLinks from 'components/AdminLinks/AdminLinks';
import Modal from 'components/Modal/Modal';
import * as api from 'api';
import { MailingList } from '../../../../../server/src/entity/store/mailingList';
import MyInput from 'components/MyInput';
import { isClient } from 'utils';
import { ISendMailerPostBody } from '../../../../../server/src/entity/store/interfaces';

const fetch = async ({ setList }) => {
  const { results } = await api.adminGetMailingList();
  setList(results);
};

let Editor;

if (isClient()) {
  import('react-draft-wysiwyg').then((e) => {
    Editor = e.Editor;
  });
}

const SendMailer = (props) => {
  const editor = React.useRef(null);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [mailState, setMailState] = useState({} as any);
  const sendMailer = async () => {
    const content = editorState.getCurrentContent();
    const message = convertToRaw(content);
    const messageHtml = draftToHtml(message);
    const body: ISendMailerPostBody = {
      to: mailState.to,
      from: mailState.from,
      customTo: mailState.customTo,
      message,
      messageHtml,
      subject: mailState.subject,
    };
    await api.adminCreateMailerApi({
      body,
    });
  };

  return (
    <div>
      <h4>Send New Mailer</h4>
      <div
        style={{
          width: '600px',
        }}
      >
        <div>
          <label>From</label>
          <MyInput type="text" setState={setMailState} path={'from'} state={mailState} />
        </div>

        <div>
          <label>To</label>
          <MyInput type="select" setState={setMailState} path={'to'} state={mailState}>
            <option value="subscribed">All Subscribed</option>
            <option value="customers">Previous Customers</option>
            <option value="custom">Custom</option>
          </MyInput>
        </div>

        <div>
          <label>Subject</label>
          <MyInput type="text" setState={setMailState} path={'subject'} state={mailState} />
        </div>

        {/* <div>
        <label>Send At</label>
        <MyInput
          type="text"
          setState={setMailState}
          path={'sendAt'}
          state={mailState}
        />
      </div> */}

        <div>
          <label>Message</label>
        </div>
        <div style={{ border: '1px solid #ddd', padding: '4px' }}>
          {Editor && isClient() && (
            <Editor ref={editor} editorState={editorState} onEditorStateChange={setEditorState} />
          )}
        </div>

        <div>
          <label>Options</label>
          <div>
            <Button onClick={() => sendMailer()}>Send</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminMailinglist = (props) => {
  const [list, setList] = useState<MailingList[]>([]);
  const [emails, setEmails] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);

  useEffect(() => {
    fetch({ setList });
  }, []);

  const newMailer = () => {
    setShowSendModal(true);
  };

  const addToMailingList = async () => {
    setLoading(true);
    const emailsArr = [];
    let buffer = [];
    for (let i = 0; i < emails.length; i++) {
      if (emails[i] === '\n' || emails[i] === ',') {
        if (buffer.length > 0) {
          emailsArr.push(buffer.join(''));
          buffer = [];
        }
      } else {
        buffer.push(emails[i]);
      }
    }
    if (buffer.length > 0) {
      emailsArr.push(buffer.join(''));
    }
    try {
      await api.adminAddEmailsToMailingList({ emails: emailsArr });
      setEmails('');
    } catch (err) {
      throw err;
    }
    await fetch({ setList });
    setLoading(false);
  };

  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <Row>
          <Col xs={2}>
            <AdminLinks />
          </Col>
          <Col xs={10}>
            <h1>Admin: Mailing List</h1>
            {showSendModal && (
              <Modal
                onOutsideClick={() => {
                  setShowSendModal(false);
                }}
              >
                <SendMailer />
              </Modal>
            )}
            <Row>
              <Col>
                <div>Add Emails to database (one per line or comma separated)</div>
                <textarea
                  value={emails}
                  onChange={(e) => {
                    setEmails(e.target.value);
                  }}
                />
                <br />
                <Button onClick={addToMailingList}>Add to mailing list</Button>
                <br />
                <br />
                <Button onClick={newMailer}>New Mailer</Button>
              </Col>
            </Row>
            <div className="spacer"></div>
            <Table>
              <thead>
                <th>Email</th>
                <th>Joined</th>
                <th>Status</th>
              </thead>
              <tbody>
                {list.map(({ id, email, createdAt, unsubscribedAt }) => {
                  return (
                    <tr key={id}>
                      <td>{email}</td>
                      <td>{moment(createdAt).format('YYYY-MM-DD')}</td>
                      <td>
                        {unsubscribedAt == null && 'Subscribed'}
                        {unsubscribedAt != null && 'Unsubscribed'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AdminMailinglist;
