import { ISendMailerPostBody } from "@src/entity/store/interfaces";

export type TMethod = 'put' | 'post' | 'get' | 'delete';

export interface IRoute<T = void> {
  uri: string;
  method: TMethod;
  body?: T;
}

export const adminGetAssetsRoute: IRoute = {
  uri: '/admin/assets',
  method: 'get',
}

export const adminDeleteAssetsRoute: IRoute = {
  uri: '/admin/assets',
  method: 'delete',
};


export const adminSendMailerRoute: IRoute<ISendMailerPostBody> = {
  uri: '/admin/mailer',
  method: 'post',
  body: {} as ISendMailerPostBody,
};
