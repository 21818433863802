import { produce } from 'immer';
import { ActionTypes } from './actions';
import { Action, AppState } from './types';

export const initialState = Object.freeze<AppState>({
  locale: 'en_US',
  admin: {},
});

export default (state: AppState = initialState, action: Action): AppState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SET_ADMIN: {
        draft.admin = action.payload;
        return;
      }
      case ActionTypes.SET_APP: {
        Object.keys(action.payload).forEach(key => {
          draft[key] = action.payload[key];
        });
        return;
      }
      case ActionTypes.SETLOCALE: {
        draft.locale = action.payload;
        return;
      }
    }
  });
