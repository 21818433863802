import React, { useCallback, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Button, Card, CardBody, CardText, Container, Row, Col } from 'reactstrap';
import StoreHeader from 'components/StoreHeader/StoreHeader';

const NotFound: React.FC<any> = () => {
  return (
    <React.Fragment>
      <StoreHeader />
      <Container>
        <h1>404 Not Found</h1>
      </Container>
    </React.Fragment>
  );
};
export default NotFound;
